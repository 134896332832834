@mixin merp-magento-button-component-styles() {
    .integration-edit-button,
    .integration-sync-button {
        @include button-primary();
    }

    .integration-edit-icon {
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
    }
}
