// TODO: Error state improvements

@mixin merp-form-base-styles() {
    form {
        @include kindling-form();
    }

    #{text-inputs()},
    select,
    textarea {
        outline: 0;
        box-shadow: 0 0 0 rem-calc(2) transparentize(get-color('primary'), 1) inset;
        color: get-color('black');

        &:focus {
            box-shadow: 0 0 0 rem-calc(2) get-color('primary') inset;
        }

        .form-field .is-empty & {
            color: get-color('grey-dark-darker');
        }
    }

    textarea {
        min-height: rem-calc(120);
        max-height: rem-calc(400);
    }

    .form-field-textarea {
        flex-basis: 100%; // TODO: Implement a more global solution
    }

    .form-field,
    .data-grid-filter-input,
    .data-grid-customization-input {
        @include form-field();

        .form-control-text,
        .form-control-textarea,
        .form-control-password,
        .form-control-select,
        .form-control-dropzone,
        .data-grid-filter-input-element {
            @include kindling-sneaky-labels();
        }

        &.has-error {
            border: rem-calc(2px) solid get-color('error');

            .field-error-message {
                display: block;
            }
        }

        .field-description {
            @include form-help-text();
        }

        .field-error-message {
            @include form-error-text();
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .form-container {
        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }
    }

    .form-submit-button {
        &[type='button'],
        &[type='submit'] {
            @include button-primary();
        }
    }

    .form-submit-button-icon {
        @include merp-icon($mi-var-save);
        margin-right: $button-icon-right-margin;
    }

    .input-specific {
        &:not(.input-specific-current) {
            display: none;
        }
    }

    .attribute-form-option {
        @include form-field();
        flex-direction: row;
    }

    .attribute-option-delete-button,
    .attribute-option-add-button {
        @include button-primary();
    }

    .fieldset-children {
        @include attribute-table();

        > .form-field {
            @include attribute-table-cell();
            margin-bottom: 0;
        }
    }

    .form-save-icon {
        @include merp-icon($mi-var-save);
        margin-right: $button-icon-right-margin;
    }

    .fieldset-hidden {
        display: none;
    }
}
