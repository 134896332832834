$item-table-margin-bottom: if(variable-exists('item-table-margin-bottom'), $item-table-margin-bottom, $global-margin);
$item-table-row-hover: if(variable-exists('item-table-row-hover'), $item-table-row-hover, 'blue-lightest');
$item-table-row-stripe-hover: if(variable-exists('item-table-row-stripe-hover'), $item-table-row-stripe-hover, $item-table-row-hover);

@mixin item-table(
    $margin: $item-table-margin-bottom,
    $row-hover: $item-table-row-hover,
    $row-stripe-hover: $item-table-row-stripe-hover
) {
    @include table($row-hover: $item-table-row-hover, $row-stripe-hover: $item-table-row-stripe-hover);

    @each $breakpoint, $responsive-margin in $margin {
        @include breakpoint($breakpoint) {
            margin-bottom: get-side($responsive-margin, 'bottom');
        }
    }
}
