@mixin merp-quote-address-component-styles() {
    $form-field-responsive-widths: (
        'small': 100%,
        'large': 50%
    ) !default;

    .quote-address-wrapper {
        @include grid-row();
    }

    .quote-address {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 50%
            )
        );

        .form-field {
            @each $breakpoint, $width in $form-field-responsive-widths {
                @include breakpoint($breakpoint) {
                    flex-basis: $width;
                }
            }
        }

        .quote-existing-address-field {
            flex-basis: 100%;

            &:not(.existing-address) {
                @each $breakpoint, $margin in $global-margin {
                    @include breakpoint($breakpoint) {
                        margin-bottom: $margin;
                    }
                }
            }
        }
    }

    .quote-address-info {
        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }

    .quote-address-view {
        border: rem-calc(2) $global-border-style get-color('grey-light');

        @each $breakpoint, $padding in $global-padding {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }
    }
}
