@mixin merp-grid-component-styles() {
    .page-main {
        padding: 0;
    }

    .page-columns {
        @include grid-row();
        flex: 1 1 auto;
    }

    .page-main-column {
        $responsive-sizes: (
            'small': 100%,
            'large': 70%,
        );
        @include grid-column($responsive-sizes);
    }

    .page-left-column {
        $responsive-sizes: (
            'small': 100%,
            'large': 30%,
        );
        @include grid-column($responsive-sizes, $flex-grow: 0);
    }

    .page-right-column {
        $responsive-sizes: (
            'small': 100%,
            'large': 30%,
        );
        @include grid-column($responsive-sizes, $flex-grow: 0);
    }
}
