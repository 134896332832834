@mixin merp-product-view-layout-styles() {
    .product-attributes-descriptions {
        .attribute-table-item-container {
            flex-basis: 100%;
        }
    }

    .product-view-wrapper {
        @include grid-row(
            $column-gutters: (
                'small': 0,
            )
        );
    }

    .product-view-info,
    .product-view-pricing,
    .product-view-statistics {
        @include box-padding(2);
    }

    .product-view-info {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 75%
            )
        );
    }

    .product-view-sidebar {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 25%
            )
        );
        background-color: get-color('blue-light');
    }
}
