@mixin merp-quote-button-component-styles() {
    .quote-convert-button {
        @include button-secondary($border-radius: 0);
    }

    .quote-delete-button {
        @include button-tertiary($border-radius: 0);
    }

    .quote-new-icon,
    .quote-edit-icon,
    .quote-convert-icon,
    .quote-delete-icon,
    .quote-save-icon {
        margin-right: $button-icon-right-margin;
    }

    .quote-new-icon {
        @include merp-icon($mi-var-plus);
        margin-right: $button-icon-right-margin;
    }

    .quote-edit-icon {
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
    }

    .quote-convert-icon {
        @include merp-icon($mi-var-shipping-truck);
        margin-right: $button-icon-right-margin;
    }

    .quote-delete-icon {
        @include merp-icon($mi-var-trash);
        margin-right: $button-icon-right-margin;
    }

    .quote-save-icon {
        @include merp-icon($mi-var-save);
        margin-right: $button-icon-right-margin;
    }

    .fieldset-legend-title {
        font-size: rem-calc(18);
        font-weight: get-font-weight('bold');
    }
}
