@mixin merp-config-layout-styles() {
    .config-index-index,
    .config-user-index {
        .page-left-column {
            $responsive-sizes: (
                'small': 100%,
                'large': 20%,
            );
            @include grid-column($responsive-sizes, $flex-grow: 0);
        }
    }
}
