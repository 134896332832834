@mixin merp-error-page-styles() {
    .error-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .error-page-image {
        width: rem-calc(500);
    }

    .error-page-code {
        margin-bottom: 0;
        color: get-color('primary');
        font-size: rem-calc(48);
    }

    .error-page-title {
        font-size: rem-calc(24);
    }

    .error-page-actions {
        @include button-group();

        .error-page-return {
            @include button-primary($border-radius: 0);
        }

        .error-page-dashboard {
            @include button-secondary($border-radius: 0);
        }
    }
}
