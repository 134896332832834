$attribute-table-margin-bottom: if(variable-exists('attribute-table-margin-bottom'), $attribute-table-margin-bottom, $global-margin);
$attribute-table-border-width: if(variable-exists('attribute-table-border-width'), $attribute-table-border-width, rem-calc(4));
$attribute-table-border-style: if(variable-exists('attribute-table-border-style'), $attribute-table-border-style, $global-border-style);
$attribute-table-border-color: if(variable-exists('attribute-table-border-color'), $attribute-table-border-color, 'grey-medium-darker');
$attribute-table-gutter: if(variable-exists('attribute-table-gutter'), $attribute-table-gutter,
    (
        'small': rem-calc(3)
    )
);
$attribute-table-item-columns: if(variable-exists('attribute-table-item-columns'), $attribute-table-item-columns,
    (
        'small': 100%,
        'medium': 100% / 2,
        'large': 100% / 3,
        'xlarge': 100% / 4,
        'xxlarge': 100% / 5
    )
);

@mixin attribute-table(
    $gutters: $attribute-table-gutter,
    $margin: $attribute-table-margin-bottom,
    $border-width: $attribute-table-border-width,
    $border-style: $attribute-table-border-style,
    $border-color: $attribute-table-border-color
) {
    @include grid-row(
        $column-gutters: $gutters,
        $vertical-gutters: true
    );
    position: relative;

    @each $breakpoint, $responsive-margin in $margin {
        @include breakpoint($breakpoint) {
            margin-bottom: get-side($responsive-margin, 'bottom');
        }
    }

    &::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-top: $border-width $border-style get-color($border-color);
        content: '';

        @each $breakpoint, $gutter in $gutters {
            @include breakpoint($breakpoint) {
                left: get-side($gutter, 'left');
            }
        }
    }
}

@mixin attribute-table-cell($responsive-sizes: $attribute-table-item-columns) {
    @include grid-column(
        $responsive-sizes: $responsive-sizes,
        $flex-grow: 1,
        $flex-shrink: 0
    );

    &:last-of-type {
        flex-grow: 99999;
    }
}

@mixin attribute-table-item() {
    @include sneaky-label();
    @include form-input-text($height-calc: false);
    @include sneaky-input();
    min-height: 100%;
}

@mixin attribute-table-label() {
    @include form-label();
    @include sneaky-label-proxy();
}

@mixin attribute-table-value() {
    &::before {
        display: inline-block;
        width: 0;
        content: $non-breaking-space;
    }
}
