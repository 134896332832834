@mixin merp-cms-button-component-styles() {
    .cms-view-icon {
        @include merp-icon($mi-var-view);
        margin-right: $button-icon-right-margin;
    }

    .cms-delete-icon {
        @include merp-icon($mi-var-trash);
        margin-right: $button-icon-right-margin;
    }
}
