@mixin merp-product-disable-button-styles() {
    .product-disable-button {
        @include button-secondary($border-radius: 0);
    }

    .product-disable-icon {
        @include button-icon($mi-var-sync);
    }
}

@mixin merp-product-cancel-button-styles() {
    .product-cancel-button {
        @include button-tertiary($border-radius: 0);
    }

    .product-cancel-icon {
        @include button-icon($mi-var-cancel);
    }
}

@mixin merp-product-save-button-styles() {
    .product-save-button {
        @include button-primary($border-radius: 0);
    }

    .product-save-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-product-edit-button-styles() {
    .product-edit-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-button-product-attribute-button-styles() {
    .product-attribute-button {
        @include button-white-blue-hover('small');
    }
}

@mixin merp-product-view-revision-button-styles() {
    .product-view-revisions-icon {
        @include button-icon($mi-var-view);
    }
}

@mixin merp-product-add-option-button-styles() {
    .product-add-option-button {
        @include button-secondary();
    }
}

@mixin merp-product-remove-option-button-styles() {
    .product-remove-option-button {
        @include button-secondary();
        height: 100%;
    }
}

@mixin merp-product-category-delete-button-styles() {
    .product-category-delete-button {
        @include button-secondary();
    }
}

@mixin merp-add-product-to-option-button-styles() {
    .add-product-to-option-button {
        @include button-primary();
        height: 100%;
    }
}

@mixin merp-product-attribute-delete-button-styles() {
    .product-attribute-delete-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-product-category-attribute-delete-button-styles() {
    .product-category-attribute-delete-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-product-button-component-styles() {
    @include merp-product-disable-button-styles();
    @include merp-product-cancel-button-styles();
    @include merp-product-save-button-styles();
    @include merp-product-edit-button-styles();
    @include merp-button-product-attribute-button-styles();
    @include merp-product-view-revision-button-styles();
    @include merp-product-add-option-button-styles();
    @include merp-product-remove-option-button-styles();
    @include merp-product-category-delete-button-styles();
    @include merp-add-product-to-option-button-styles();
    @include merp-product-attribute-delete-button-styles();
    @include merp-product-category-attribute-delete-button-styles();
}
