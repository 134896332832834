@mixin merp-category-new-button-styles() {
    .category-new-button-icon {
        @include button-icon($mi-var-plus);
    }
}

@mixin merp-category-edit-button-styles() {
    .product-edit-categories-button {
        @include button-primary('small');
    }

    .category-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-category-delete-button-styles() {
    .category-delete-button-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-category-save-button-styles() {
    .product-category-save-button {
        @include button-primary();
    }

    .category-save-icon,
    .product-category-save-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-product-category-button-component-styles() {
    @include merp-category-new-button-styles();
    @include merp-category-edit-button-styles();
    @include merp-category-delete-button-styles();
    @include merp-category-save-button-styles();
}
