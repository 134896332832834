@mixin merp-revision-history-item-styles() {
    .revision-history-items {
        margin: 0;

        > li {
            margin: 0;
            list-style: none;

            > a {
                display: block;
                padding: rem-calc(12);
                width: 100%;

                &.active {
                    background-color: get-color('white');

                    @each $breakpoint, $border-width in $global-border-width {
                        @include breakpoint($breakpoint) {
                            border-bottom: rem-calc($border-width) $global-border-style get-color('blue');
                        }
                    }
                }

                &:not(.active) {
                    @each $breakpoint, $border-width in $global-border-width {
                        @include breakpoint($breakpoint) {
                            border-bottom: rem-calc($border-width) $global-border-style get-color($global-border-color);
                        }
                    }
                }
            }
        }
    }
}

@mixin merp-revision-head-styles() {
    .revisions-head {
        @include grid-row($column-gutters: ());
        align-items: center;

        > .content-heading {
            @include grid-column((), $flex-grow: 1);
        }

        .show-all-checkbox-wrapper {
            text-transform: uppercase;

            input[type='checkbox'] {
                @include form-checkbox();
            }
        }
    }
}

@mixin merp-revision-table-styles() {
    .revision-table {
        .changed {
            &,
            &:hover {
                background-color: transparentize(get-color('primary'), 0.6);
            }

            &:nth-child(even) {
                background-color: transparentize(get-color('primary'), 0.8);

                &:hover {
                    background-color: transparentize(get-color('primary'), 0.6);
                }
            }
        }

        &:not(.show-all) {
            tbody {
                > :not(.changed) {
                    display: none;
                }
            }
        }

        #{text-inputs()},
        select,
        textarea {
            box-shadow: 0 0 0 rem-calc(2) darken(get-color($input-background-color), 10%) inset;

            &:focus {
                box-shadow: 0 0 0 rem-calc(2) get-color('primary') inset;
            }
        }

        .form-field {
            > .form-label-text {
                display: none;
            }
        }
    }

    .revision-table-label-use {
        display: flex;

        .form-field {
            flex-grow: 1;
            margin-bottom: 0;
        }

        button {
            @include button-secondary();
            margin-left: auto;
        }
    }
}

@mixin merp-revision-component-styles() {
    @include merp-revision-history-item-styles();
    @include merp-revision-head-styles();
    @include merp-revision-table-styles();
}
