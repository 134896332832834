@mixin merp-dropzone-component-styles() {
    @keyframes passing-through {
        0% {
            transform: translateY(rem-calc(40));
            opacity: 0;
        }

        30%,
        70% {
            transform: translateY(rem-calc(0));
            opacity: 1;
        }

        100% {
            transform: translateY(rem-calc(-40));
            opacity: 0;
        }
    }

    @keyframes slide-in {
        0% {
            transform: translateY(rem-calc(40));
            opacity: 0;
        }

        30% {
            transform: translateY(rem-calc(0));
            opacity: 1;
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        10% {
            transform: scale(1.1);
        }

        20% {
            transform: scale(1);
        }
    }

    .dropzone {
        @include box-padding(2);
        border: rem-calc(2) $global-border-style get-color($global-border-color);
        background-color: get-color('blue-light');
        padding: rem-calc(20);
        min-height: rem-calc(150);
        box-sizing: border-box;

        &.dz-clickable {
            cursor: pointer;

            * {
                cursor: default;
            }

            .dz-message {
                cursor: pointer;
            }
        }

        &.dz-started {
            .dz-message {
                display: none;
            }
        }

        &.dz-drag-hover {
            border-style: $global-border-style;

            .dz-message {
                opacity: 0.5;
            }
        }

        .dz-message {
            margin: rem-calc(2 0);
            text-align: center;
        }

        .dz-preview {
            display: inline-block;
            position: relative;
            margin: rem-calc(16);
            min-height: rem-calc(100);
            vertical-align: top;

            &:hover {
                z-index: 1000;

                .dz-details {
                    opacity: 1;
                }

                .dz-image img {
                    transform: scale(1.05, 1.05);
                    filter: blur(rem-calc(8));
                }
            }

            &.dz-file-preview {
                .dz-image {
                    border-radius: rem-calc(20);
                    background: get-color('grey-medium-darker');
                }

                .dz-details {
                    opacity: 1;
                }
            }

            &.dz-image-preview {
                .dz-details {
                    transition: opacity get-duration('slow') get-timing-function('linear');
                }
            }

            .dz-remove {
                display: block;
                border: 0;
                cursor: pointer;
                text-align: center;
                font-size: rem-calc(14);

                &:hover {
                    text-decoration: underline;

                    .dz-details {
                        opacity: 1;
                    }
                }
            }

            .dz-details {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 20;
                padding: 2em 1em;
                min-width: 100%;
                max-width: 100%;
                text-align: center;
                line-height: 150%;
                color: transparentize(get-color('black-pitch'), 0.1);
                font-size: rem-calc(13);

                .dz-size {
                    margin-bottom: 1em;
                    font-size: rem-calc(16);

                    span {
                        border-radius: rem-calc(3);
                        background-color: transparentize(get-color('white'), 0.4);
                        padding: 0 0.4em;
                    }
                }

                .dz-filename {
                    text-transform: none;
                    white-space: nowrap;

                    span {
                        border-radius: rem-calc(3);
                        background-color: transparentize(get-color('white'), 0.4);
                        padding: 0 0.4em;
                    }

                    &:hover span {
                        border: rem-calc(1) $global-border-style transparentize(get-color('grey-lightest'), 0.2);
                        background-color: transparentize(get-color('white'), 0.2);
                    }

                    &:not(:hover) {
                        overflow: hidden;
                        text-overflow: ellipsis;

                        span {
                            border: rem-calc(1) $global-border-style transparent;
                        }
                    }
                }
            }

            .dz-image {
                display: block;
                position: relative;
                z-index: 10;
                border-radius: rem-calc(20);
                width: rem-calc(120);
                height: rem-calc(120);
                overflow: hidden;

                img {
                    display: block;
                }
            }

            &.dz-success .dz-success-mark {
                animation: passing-through get-duration('spin') cubic-bezier(0.77, 0, 0.175, 1);
            }

            &.dz-error {
                .dz-error-mark {
                    animation: slide-in get-duration('spin') cubic-bezier(0.77, 0, 0.175, 1);
                }

                .dz-error-message {
                    display: block;
                    margin-top: rem-calc(15);
                    width: rem-calc(150);
                }

                &:hover .dz-error-message {
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            .dz-success-mark,
            .dz-error-mark {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                opacity: 0;
                z-index: 500;
                margin-top: rem-calc(-27);
                margin-left: rem-calc(-27);
                pointer-events: none;

                svg {
                    display: block;
                    width: rem-calc(54);
                    height: rem-calc(54);
                }
            }

            &.dz-processing .dz-progress {
                transition: opacity get-duration('slow') get-timing-function('linear');
                opacity: 1;
            }

            &.dz-complete .dz-progress {
                transition: opacity get-duration('slow') get-timing-function('ease-in');
                opacity: 0;
            }

            &:not(.dz-processing) .dz-progress {
                animation: pulse get-duration('success-failure') ease infinite;
            }

            .dz-progress {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: scale(1);
                opacity: 1;
                z-index: 1000;
                margin-top: rem-calc(-8);
                margin-left: rem-calc(-40);
                border-radius: rem-calc(8);
                background: transparentize(get-color('white'), 0.1);
                width: rem-calc(80);
                height: rem-calc(16);
                overflow: hidden;
                pointer-events: none;

                .dz-upload {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    transition: width get-duration('slow') get-timing-function('ease-in-out');
                    background: get-color('black');
                    width: 0;
                }
            }

            .dz-error-message {
                display: none;
                position: absolute;
                top: rem-calc(130);
                left: rem-calc(-10);
                transition: opacity get-duration('slow') get-timing-function('ease');
                opacity: 0;
                z-index: 1000;
                border-radius: rem-calc(8);
                background: get-color('red');
                padding: 0.5em 1.2em;
                width: rem-calc(140);
                color: get-color('white');
                font-size: rem-calc(13);
                pointer-events: none;

                &::after {
                    position: absolute;
                    top: rem-calc(-6);
                    left: rem-calc(64);
                    border-right: rem-calc(6) $global-border-style transparent;
                    border-bottom: rem-calc(6) $global-border-style get-color('red');
                    border-left: rem-calc(6) $global-border-style transparent;
                    width: 0;
                    height: 0;
                    content: '';
                }
            }
        }
    }
}
