@mixin merp-notify-component-styles() {
    .notify-modal {
        @include modal();
        position: relative;
    }

    .modal-overlay {
        @include modal-overlay();
    }

    .modal-fullscreen {
        @include modal($fullscreen: true);
    }

    .modal-open-button,
    .modal-close-button {
        @include button-style('primary');
        @include button-size();
    }

    body {
        @include body-modal-open();
    }

    .confirm-modal,
    .alert-modal {
        @include modal();
        position: relative;
    }

    .notify-modal-confirm-button,
    .notify-modal-cancel-button,
    .notify-modal-close-button {
        @include button-primary();
        margin: rem-calc(10);
    }

}
