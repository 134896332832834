@mixin merp-animations-keyframes() {
    @keyframes spin {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
