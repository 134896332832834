@import 'merp-icons/variables.scss';

$font-family-merp-icons: if(variable-exists('font-family-merp-icons'), $font-family-merp-icons, 'MERP Icons');

@mixin merp-icon-font-styles() {
    @font-face {
        font-family: $font-family-merp-icons;
        font-weight: get-font-weight('normal');
        font-style: normal;
        src: url('#{$teemo-font-path}/merp-icons/merp.eot');
        src: url('#{$teemo-font-path}/merp-icons/merp.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/merp-icons/merp.woff') format('woff'),
        url('#{$teemo-font-path}/merp-icons/merp.ttf') format('truetype');
    }
}

@mixin merp-icon-base() {
    vertical-align: -10%;
    text-transform: none;
    line-height: inherit;
    font-family: $font-family-merp-icons;
    font-weight: get-font-weight('normal');
    font-style: normal;
    font-variant: normal;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @content;
}

@mixin merp-icon($icon, $use-before: true) {
    $selector: '::before';

    @if $use-before != true {
        $selector: '::after';
    }

    &#{$selector} {
        @include merp-icon-base() {
            content: $icon;
            @content;
        }
    }
}
