$striped-background-color: if(variable-exists('striped-background-color'), $striped-background-color, smart-scale-color($table-background-color, $table-color-scale));

@mixin merp-picking-flyout-main-styles() {
    .picking-flyout-container {
        @include data-grid-flyout();
        @include data-grid-cart();
        background-color: get-color('white');
    }

    .picking-flyout-header {
        @include data-grid-flyout-header();
    }

    .picking-flyout-body {
        position: relative;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .picking-order {
        @include box-padding();
    }

    .picking-flyout-footer {
        @include box-section($padding-ratio: 0);
        flex: 0 0 auto;
    }

    .picking-item {
        @include box-padding();

        &:nth-child(even) {
            border-bottom: 0;
            background-color: get-color($striped-background-color);
            color: get-color(color-pick-contrast($striped-background-color));
        }
    }

    .picking-item-section {
        display: flex;

        .picking-item-label {
            flex: 1 0 auto;
            font-weight: get-font-weight('bold');
        }
    }
}

@mixin merp-picking-flyout-button-styles() {
    .picking-flyout-footer-buttons {
        @include button-group($bottom-margin-ratio: 0);
    }

    .picking-flyout-clear-button,
    .picking-flyout-save-button,
    .picking-flyout-start-button,
    .picking-flyout-close-button {
        @include button-group-item();
    }

    .picking-flyout-remove-item-button {
        @include button-secondary();
        margin-top: rem-calc(15);
        width: 100%;
    }

    .picking-flyout-clear-button {
        @include button-secondary($border-radius: 0);

        .picking-flyout-clear-icon {
            @include merp-icon($mi-var-cancel);
        }
    }

    .picking-flyout-save-button {
        @include button-primary($border-radius: 0);

        .picking-flyout-save-icon {
            @include merp-icon($mi-var-save);
        }
    }

    .picking-flyout-start-button {
        @include button-primary($border-radius: 0);

        .picking-flyout-start-icon {
            @include merp-icon($mi-var-sync); // @todo: update placeholder icon
        }
    }

    .picking-flyout-close-button {
        @include button-tertiary($border-radius: 0);

        .picking-flyout-close-icon {
            @include merp-icon($mi-var-close);
        }
    }
}

@mixin merp-picking-flyout-component-styles() {
    @include merp-picking-flyout-main-styles();
    @include merp-picking-flyout-button-styles();
}
