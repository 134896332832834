@mixin merp-product-in-stock-icon-styles() {
    .product-stock-in-stock-icon,
    .product-status-enabled-icon {
        @include merp-icon($mi-var-circle);
    }

    .product-stock-in-stock-icon,
    .product-status-enabled-icon {
        color: get-color('green');
    }
}

@mixin merp-product-out-of-stock-icon-styles() {
    .product-stock-out-of-stock-icon,
    .product-status-disabled-icon {
        @include merp-icon($mi-var-circle);
    }

    .product-stock-out-of-stock-icon,
    .product-status-disabled-icon {
        color: get-color('red');
    }
}

@mixin merp-product-stock-status-component-styles() {
    @include merp-product-in-stock-icon-styles();
    @include merp-product-out-of-stock-icon-styles();

    .product-stock-status {
        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-right: $margin;
            }
        }
    }

    .product-stock-status,
    .product-enabled-status {
        text-transform: uppercase;
        font-weight: get-font-weight('bold');
    }

    .product-stock-status {
        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-right: $margin;
            }
        }
    }
}
