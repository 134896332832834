@mixin merp-quote-bundle-component-styles() {
    #quote-edit-form, #quote-view-items {
        .bundle-option:not(:last-child) {
            border-bottom: $global-border-style get-color($global-border-color);
        }

        .option-wrapper:not(:last-child) {
            border-bottom: $global-border-style get-color($global-border-color);
        }
    }
}
