@mixin merp-sales-invoice-view-layout-styles() {
    .sales-invoice-view {
        .page-main-column {
            @include grid-row($column-gutters: ());

            > .sales-view-tab-items {
                @each $breakpoint, $gutter in $grid-column-gutters {
                    @include breakpoint($breakpoint) {
                        padding: $gutter;
                    }
                }
            }

            > .sales-view-tab-content {
                @include grid-column((), $flex-grow: 1);

                @each $breakpoint, $gutter in $grid-column-gutters {
                    @include breakpoint($breakpoint) {
                        padding: $gutter;
                    }
                }
            }
        }
    }
}
