@mixin merp-core-more-options-button-component-styles() {
    .drop-down-button {
        @include button-secondary();
    }

    .drop-down-list {
        @include dropdown($include-padding: false);
        border-radius: rem-calc(5);

        .drop-down-list-link {
            @include dropdown-link();

            @each $breakpoint, $padding in $dropdown-link-padding {
                @include breakpoint($breakpoint) {
                    padding: $padding ($padding * 2);
                }
            }
        }

        .drop-down-list-item {
            @include dropdown-list-item();
        }
    }
}
