@mixin merp-unpick-flyout-container-styles() {
    .unpick-flyout-container {
        @include data-grid-flyout();
        @include data-grid-cart();
        background-color: get-color('white');
    }
}

@mixin merp-unpick-flyout-header-styles() {
    .unpick-flyout-header {
        @include data-grid-flyout-header();
    }
}

@mixin merp-unpick-flyout-body-styles() {
    .unpick-flyout-body {
        position: relative;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .unpick-order {
        @include box-padding();
    }

    .unpick-flyout-unpick-item {
        @include flex-accordion-row();
        margin-top: rem-calc(10);
        border-top: rem-calc(2) solid get-color('grey-light');
        padding-top: rem-calc(10);
    }

    .unpick-flyout-remove-item-button {
        @include button-secondary($button-size: 'small');
        margin-top: 0;
        margin-left: auto;
    }

    .unpick-flyout-remove-item-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-unpick-flyout-footer-styles() {
    .unpick-flyout-footer {
        @include box-section($padding-ratio: 0);
        flex: 0 0 auto;
    }
}

@mixin merp-unpick-flyout-footer-button-styles() {
    .unpick-flyout-footer-buttons {
        @include button-group($bottom-margin-ratio: 0);
    }

    .unpick-flyout-clear-button,
    .unpick-flyout-save-button,
    .unpick-flyout-start-button,
    .unpick-flyout-close-button {
        @include button-group-item();
    }
}

@mixin merp-unpick-flyout-clear-button-styles() {
    .unpick-flyout-clear-button {
        @include button-secondary($border-radius: 0);
    }

    .unpick-flyout-clear-icon {
        @include button-icon($mi-var-cancel);
    }
}

@mixin merp-unpick-flyout-save-button-styles() {
    .unpick-flyout-save-button {
        @include button-primary($border-radius: 0);
    }

    .unpick-flyout-save-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-unpick-flyout-start-button-styles() {
    .unpick-flyout-start-button {
        @include button-primary($border-radius: 0);
    }

    .unpick-flyout-start-icon {
        @include button-icon($mi-var-sync); // @todo: update placeholder icon
    }
}

@mixin merp-unpick-flyout-close-button-styles() {
    .unpick-flyout-close-button {
        @include button-tertiary($border-radius: 0);
    }

    .unpick-flyout-close-icon {
        @include button-icon($mi-var-close);
    }
}

@mixin merp-unpick-flyout-component-styles() {
    @include merp-unpick-flyout-container-styles();
    @include merp-unpick-flyout-header-styles();
    @include merp-unpick-flyout-body-styles();
    @include merp-unpick-flyout-footer-styles();
    @include merp-unpick-flyout-footer-button-styles();
    @include merp-unpick-flyout-clear-button-styles();
    @include merp-unpick-flyout-save-button-styles();
    @include merp-unpick-flyout-start-button-styles();
    @include merp-unpick-flyout-close-button-styles();
}
