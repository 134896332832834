@mixin merp-ajax-overlay-component-styles() {
    .ajax-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: get-z-index('ajax-overlay');
        background-color: transparentize(get-color('white'), 0.5);
        width: 100%;
        height: 100%;
    }

    body {
        &.is-loading {
            .ajax-overlay {
                display: block;
            }
        }
    }
}
