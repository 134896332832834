@mixin merp-picking-view-layout-styles() {
    .picking-index-view {
        .form-container {
            padding: 0;

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    margin-bottom: $margin;
                }
            }
        }
    }
}
