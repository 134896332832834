@mixin merp-sales-order-comments-grid-component-styles() {
    $order-comment-padding: $global-padding !default;
    $order-comment-avatar-sizes: (
        'small': rem-calc(110)
    ) !default;
    $order-comment-icon-sizes: (
        'small': rem-calc(16)
    ) !default;
    $order-comment-avatar-responsive-sizes: (
        'small': 100%,
        'medium': 20%,
        'xxlarge': 10%,
    ) !default;
    $order-comment-information-responsive-sizes: (
        'small': 100%,
        'medium': 80%,
        'xxlarge': 90%,
    ) !default;

    .sales-index-view {
        .add-comment-button {
            @include button-style('primary');
            @include button-size('large');

            .add-comment-button-plus {
                @include merp-icon($mi-var-plus);
                margin-right: $button-icon-right-margin;
            }
        }

        .comment-grid-actions {
            align-items: center;
            justify-content: flex-end;
        }

        .order-comment-section {
            border-top: rem-calc(3) $global-border-style get-color('secondary');
            border-bottom: rem-calc(3) $global-border-style get-color('primary');
            overflow: hidden;

            .order-comment-row {
                @include grid-row();

                @each $breakpoint, $padding in $order-comment-padding {
                    @include breakpoint($breakpoint) {
                        padding: $padding;
                    }
                }

                .comment-row-avatar-wrapper {
                    @include grid-column($order-comment-avatar-responsive-sizes);

                    .comment-row-avatar {
                        border-radius: rem-calc(99999);
                        box-shadow: rem-calc(0 1 4) transparentize(get-color('black-pitch'), 0.6);
                        background-color: get-color('white');
                        overflow: hidden;
                        color: get-color('grey-light');

                        .comment-row-avatar-placeholder {
                            @include merp-icon($mi-var-user);
                        }

                        @each $breakpoint, $size in $order-comment-avatar-sizes {
                            @include breakpoint($breakpoint) {
                                width: $size;
                                height: $size;
                                font-size: $size;
                            }
                        }
                    }
                }

                .comment-row-comment-information {
                    @include grid-column($order-comment-information-responsive-sizes);

                    @each $breakpoint, $width in $order-comment-information-responsive-sizes {
                        @include breakpoint($breakpoint) {
                            max-width: $width;
                        }
                    }

                    .comment-date-type-information,
                    .comment,
                    .customer-notified-section {
                        @each $breakpoint, $padding in $order-comment-padding {
                            @include breakpoint($breakpoint) {
                                padding-top: $padding;
                            }
                        }
                    }

                    .comment-user-name-magento-sync {
                        display: flex;
                        font-size: rem-calc(16);
                        font-weight: get-font-weight('bold');

                        .magento-sync {
                            margin-left: auto;
                            text-transform: uppercase;
                            font-size: rem-calc(12);

                            .synced,
                            .not-synced {
                                @include merp-icon($mi-var-sync);

                                @each $breakpoint, $size in $order-comment-icon-sizes {
                                    @include breakpoint($breakpoint) {
                                        width: $size;
                                        height: $size;
                                        font-size: $size;
                                    }
                                }
                            }

                            .synced {
                                color: get-color('green');
                            }

                            .not-synced {
                                color: get-color('red');
                            }
                        }
                    }

                    .comment-date-type-information {
                        font-size: rem-calc(12);
                    }

                    .customer-notified {
                        @include merp-icon($mi-var-checkbox);
                        color: get-color('primary');
                    }

                    .customer-notified-checkbox {
                        @include form-checkbox();

                        &:checked {
                            + * {
                                &::before {
                                    color: get-color('primary');
                                }
                            }
                        }
                    }
                }

                &:nth-child(even) {
                    background-color: get-color('blue-light');
                }
            }
        }

        .order-comment-form {
            .order-comment-form-title {
                font-size: rem-calc(18);
                font-weight: get-font-weight('bold');
            }

            .form-field {
                margin-bottom: 0;
                border: rem-calc(2) $global-border-style get-color('grey-light');

                &:not(:first-child) {
                    border-top: 0;

                    @each $breakpoint, $padding in $global-padding {
                        @include breakpoint($breakpoint) {
                            padding: $padding;
                        }
                    }
                }

                .comment-form-checkbox {
                    @include form-checkbox-round();

                    &:checked {
                        + * {
                            &::before {
                                color: get-color('primary');
                            }
                        }
                    }
                }
            }

            textarea {
                background-color: get-color('white');

                &:focus {
                    box-shadow: none;
                }
            }

            .add-comment-button {
                @each $breakpoint, $margin in $global-margin {
                    @include breakpoint($breakpoint) {
                        margin-top: $margin;
                    }
                }
            }

            label {
                text-transform: none;
            }
        }
    }
}
