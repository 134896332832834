$main-menu-user-avatar-sizes: if(variable-exists('main-menu-user-avatar-sizes'), $main-menu-user-avatar-sizes,
    (
        'small': rem-calc(32)
    )
);

@mixin main-menu-item-hover() {
    box-shadow: rem-calc(5) 0 0 get-color($main-menu-item-hover-color) inset;
    background-color: get-color($main-menu-item-hover-color);
    color: get-color($main-menu-text-hover-color);
}

@mixin main-menu-item() {
    @include transition((color, background-color, box-shadow), 'fastest');
    display: flex;
    align-items: center;
    box-shadow: 0 0 0 get-color($main-menu-item-hover-color) inset;
    padding: rem-calc(8 20);
    color: get-color($main-menu-text-color);

    &.is-active,
    &:hover,
    &:focus {
        @include main-menu-item-hover();
    }
}

@mixin main-menu-item-icon() {
    @include transition(color, 'fastest');
    margin-right: 0.5rem;
    line-height: 1rem;
    color: get-color($main-menu-item-hover-color);
    font-size: 200%;
}

@mixin main-menu-item-icon-hover() {
    color: get-color($main-menu-text-hover-color);
}

@mixin merp-main-menu-component-styles() {
    .main-menu {
        display: flex;
        flex-direction: column;
        background-color: smart-scale-color(get-color($main-menu-base-color), $main-menu-color-scale-ratio);

        .menu {
            flex: 1 1 0;
            align-content: flex-start;
            align-items: stretch;
            margin: 0;
            min-height: 0;
            overflow-y: auto;

            li {
                display: block;
                margin: 0;
            }

            a {
                @include main-menu-item();

                &.is-active,
                &:hover,
                &:focus {
                    .main-menu-icon {
                        @include main-menu-item-icon-hover();
                    }
                }

                .main-menu-icon {
                    @include main-menu-item-icon();
                }
            }

            @for $i from 1 through $main-menu-nested-iterations {
                $nested-selector: '';

                @for $j from 1 through $i {
                    $nested-selector: '#{$nested-selector} .menu';
                }

                #{$nested-selector} {
                    .no-js & {
                        display: none;
                    }

                    a {
                        background-color: smart-scale-color(get-color($main-menu-base-color), $main-menu-color-scale-ratio * $i * 2);

                        &.is-active,
                        &:hover,
                        &:focus {
                            background-color: smart-scale-color(get-color($main-menu-base-color), $main-menu-color-scale-ratio * $i * 3);
                        }
                    }
                }
            }

            .level-0 {
                > a {
                    border-bottom: rem-calc(2) solid get-color($main-menu-base-color);
                    padding: rem-calc(15 20);
                    text-transform: uppercase;
                    font-weight: get-font-weight('bold');
                }
            }

            .is-parent {
                > a {
                    @include merp-icon($mi-var-arrow-down, false) {
                        @include transition(transform);
                        flex: 1 0 auto;
                        text-align: right;
                    }
                    perspective: rem-calc(140);
                    perspective-origin: right center;
                }

                &[aria-expanded='true'] > a {
                    &::after {
                        transform: rotateX(180deg);
                    }
                }
            }
        }
    }

    .main-menu-user {
        @include transition(background-color);
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        background-color: get-color($main-menu-base-color);
        color: get-color($main-menu-text-hover-color);
        font-weight: get-font-weight('bold');

        @each $breakpoint, $height in $topbar-heights {
            @include breakpoint($breakpoint) {
                height: $height;
            }
        }

        @each $breakpoint, $padding in $global-padding {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }

        &:hover,
        &:focus {
            background-color: get-color($main-menu-item-hover-color);
            color: get-color($main-menu-text-hover-color);
        }

        .main-menu-user-avatar {
            display: inline-block;
            border-radius: rem-calc(99999);
            box-shadow: rem-calc(0 1 4) transparentize(get-color('black-pitch'), 0.6);
            background-color: get-color('primary');
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .user-avatar-placeholder {
                @include merp-icon($mi-var-user);
                line-height: 1.4;
            }

            @each $breakpoint, $size in $main-menu-user-avatar-sizes {
                @include breakpoint($breakpoint) {
                    width: $size;
                    height: $size;
                    font-size: $size;
                }
            }

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    margin-right: ($margin / 2);
                }
            }
        }
    }

    .main-menu-icon-customer {
        @include merp-icon($mi-var-customers);
    }

    .main-menu-icon-catalog {
        @include merp-icon($mi-var-catalog);
    }

    .main-menu-icon-sales {
        @include merp-icon($mi-var-sales);
    }

    .main-menu-icon-system {
        @include merp-icon($mi-var-gear); //@todo - change placeholder icon
    }

    .main-menu-icon-order-fulfilment {
        @include merp-icon($mi-var-out-going); //@todo - change placeholder icon
    }

    .main-menu-icon-supply-chain {
        @include merp-icon($mi-var-backorder); //@todo - change placeholder icon
    }

    .main-menu-icon-misc {
        @include merp-icon($mi-var-system);
    }
}
