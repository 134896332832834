@mixin merp-products-per-location-table-component-styles() {
    .location-products-edit {
        .item-search-input {
            @include grid-column(('small': 100%, 'large': (100% / 6)));
        }

        .add-item-button {
            @include button-primary('small');

            .item-add-icon {
                @include merp-icon($mi-var-plus);
                margin-right: $button-icon-right-margin;
            }
        }

        .data-grid-container {
            padding: 0;

            td {
                &.contains-input {
                    padding: 0;

                    .form-field {
                        margin-bottom: 0;
                    }
                }
            }

            #{text-inputs()},
            select {
                background-color: transparent;
            }
        }
    }
}
