@mixin merp-sales-order-item-table-styles() {
    .order-view-table-foot {
        position: relative;
        border-top-width: $table-border-width;
        background-color: get-color('blue-light');

        &::before {
            position: absolute;
            top: 0;
            right: ($table-border-width * 2);
            left: ($table-border-width * -1);
            border: $table-border-width $table-border-style get-color($global-border-color);
            content: '';
        }

        .order-view-table-total {
            text-align: right;
        }
    }
}
