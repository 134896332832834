@mixin merp-sales-view-layout-styles() {
    $sales-shipment-responsive-breakpoint: 'medium' !default;

    .sales-index-view {
        .page-main-column {
            @include grid-row($column-gutters: ());

            > .sales-view-tab-items {
                @each $breakpoint, $gutter in $grid-column-gutters {
                    @include breakpoint($breakpoint) {
                        padding: $gutter;
                    }
                }
            }

            > .sales-view-tab-content {
                @include grid-column((), $flex-grow: 1);

                @each $breakpoint, $gutter in $grid-column-gutters {
                    @include breakpoint($breakpoint) {
                        padding: $gutter;
                    }
                }

                .data-grid-container {
                    padding: 0;
                }
            }
        }
    }

    .sales-columns-row {
        @include grid-row();
    }

    .sales-items-grid-left {
        $responsive-sizes: (
            'small': 100%,
            'xlarge': 50%,
        );
        @include grid-column($responsive-sizes);
    }

    .sales-items-grid-right {
        $responsive-sizes: (
            'small': 100%,
            'xlarge': 50%,
        );
        @include grid-column($responsive-sizes);
    }

    .sales-address-grid-left {
        $responsive-sizes: (
            'small': 100%,
            'medium': 50%,
        );
        @include grid-column($responsive-sizes);
    }

    .sales-address-grid-right {
        $responsive-sizes: (
            'small': 100%,
            'medium': 50%,
        );
        @include grid-column($responsive-sizes);

        .attribute-table {
            &::before {
                display: none;
            }
        }
    }

    .sales-address {
        @include breakpoint($sales-shipment-responsive-breakpoint) {
            margin-top: 0;
        }

        .address-header {
            @include breakpoint($sales-shipment-responsive-breakpoint) {
                margin-top: 0;
            }
        }

        .attribute-table {
            &::before {
                @include breakpoint($sales-shipment-responsive-breakpoint) {
                    border-top: none;
                }
            }
        }
    }
}
