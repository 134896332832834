$footer-background-color: if(variable-exists('footer-background-color'), $footer-background-color, 'blue-light');
$footer-text-color: if(variable-exists('footer-text-color'), $footer-text-color, 'grey-dark-darker');

@mixin merp-footer-component-styles() {
    .footer {
        @include toolbar(center, center);
        @include set-background-color($footer-background-color, $footer-text-color);
        text-transform: uppercase;

        @each $breakpoint, $height in $footer-heights {
            @include breakpoint($breakpoint) {
                height: $height;

                .footer-image {
                    height: ($height * 0.4);
                }
            }
        }

        .footer-text {
            @each $breakpoint, $padding in $global-padding {
                @include breakpoint($breakpoint) {
                    padding: $padding;
                }
            }
        }
    }
}
