@mixin merp-packing-information-component-styles() {
    .packing-edit-top {
        display: flex;
        justify-content: space-between;
        border-bottom: 0;

        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }

    .packing-information-list {
        dl {
            margin-left: 0;
        }
    }
}
