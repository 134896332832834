@mixin merp-core-permissions-tabs-component-styles() {
    .permission-tabs {
        @include tabs-container();
    }

    .permission-tabs-item {
        @include tabs-item();
    }

    .permission-tabs-title {
        @include tabs-title();
    }
}
