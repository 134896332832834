@mixin merp-quote-customer-component-styles() {
    .quote-customer {
        .fieldset-children {
            .form-field {
                flex: 1 0 auto;

                &:last-of-type {
                    flex: 1 0 auto;
                }
            }
        }
    }

    .quote-customer-info,
    .quote-store-info {
        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }
}
