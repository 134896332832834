@mixin merp-sales-heading-component-styles() {
    .order-view-order-information,
    .order-view-account-attributes,
    .order-view-customer-notes,
    .order-view-order-items {
        h5 {
            @include typography-heading-margin($heading-type: 'h5', $last-child-no-margin: false);
        }
    }
}
