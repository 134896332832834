$button-icon-margin: if(variable-exists('button-icon-margin'), $button-icon-margin, $button-icon-right-margin);

@mixin button-primary(
    $button-size: 'regular',
    $border-radius: $button-border-radius
) {
    @include button-size($button-size);
    @include button-style('primary', $border-radius: $border-radius);
}

@mixin button-secondary(
    $button-size: 'regular',
    $border-radius: $button-border-radius
) {
    @include button-size($button-size);
    @include button-style('secondary', $border-radius: $border-radius);
}

@mixin button-tertiary(
    $button-size: 'regular',
    $border-radius: $button-border-radius
) {
    @include button-size($button-size);
    @include button-style('tertiary', $border-radius: $border-radius);
}

@mixin button-white(
    $button-size: 'regular',
    $border-radius: $button-border-radius
) {
    @include button-size($button-size);
    @include button-style('white', $border-radius: $border-radius);
}

@mixin button-white-blue-hover(
    $button-size: 'regular',
    $border-radius: $button-border-radius
) {
    @include button-size($button-size);
    @include button-style(
        $background-color: 'white',
        $background-color-hover: 'primary',
        $border-width: ('small': rem-calc(2)),
        $border-width-hover: ('small': rem-calc(2)),
        $border-color: $global-border-color,
        $border-color-hover: 'primary',
        $border-radius: $button-border-radius
    );
}

@mixin button-group($bottom-margin-ratio: 1) {
    @include toolbar($justify-content: space-between, $align-items: stretch);
    border-radius: $button-border-radius;
    overflow: hidden;

    @each $breakpoint, $margin in $button-group-margin-bottom {
        @include breakpoint($breakpoint) {
            margin-bottom: (get-side($margin, 'bottom') * $bottom-margin-ratio);
        }
    }
}

@mixin button-icon(
    $icon,
    $right-margin: $button-icon-margin
) {
    @include merp-icon($icon);
    margin-right: $right-margin;
}
