@mixin merp-customer-revision-table-head-component-styles() {
    .customer-info {
        display: flex;
        justify-content: flex-end;

        .customer-address-revisions-button {
            @include button-secondary();
        }

        .customer-addresses-dropdown {
            @include dropdown($include-padding: false);
            border-radius: rem-calc(5);

            .customer-address-dropdown-link {
                @include dropdown-link();

                @each $breakpoint, $padding in $dropdown-link-padding {
                    @include breakpoint($breakpoint) {
                        padding: $padding ($padding * 2);
                    }
                }
            }

            .customer-address-dropdown-list-item {
                @include dropdown-list-item();

                &:first-of-type {
                    .user-dropdown-link {
                        @each $breakpoint, $padding in $dropdown-link-padding {
                            padding-top: ($padding * 2);
                        }
                    }
                }

                &:last-of-type {
                    .user-dropdown-link {
                        @each $breakpoint, $padding in $dropdown-link-padding {
                            padding-bottom: ($padding * 2);
                        }
                    }
                }
            }
        }
    }
}
