@mixin global-message($background-color, $text-color, $icon: false, $icon-color: null) {
    @include box-container($margin-ratio: 0, $border: false, $flex-direction: row);
    @include box-section($background-color: $background-color, $text-color: $text-color);

    @if $icon {
        @include merp-icon($icon) {
            margin-right: rem-calc(5);
            vertical-align: middle;
            line-height: 1.3;
            font-size: 140%;

            @if $icon-color {
                color: get-color($icon-color);
            }
        }
    }
}

@mixin merp-messages-component-styles() {
    .messages {
        &:not(:empty) {
            @include box-container();
        }
    }

    .global-message-error {
        @include global-message('error-light', 'error-dark', $mi-var-cancel, 'error');
    }

    .global-message-warning {
        @include global-message('warning-light', 'warning-dark', $mi-var-warning, 'warning');
    }

    .global-message-notice {
        @include global-message('primary-light', 'primary-dark', $mi-var-info, 'primary');
    }

    .global-message-success {
        @include global-message('success-light', 'success-dark', $mi-var-check-circled, 'success');
    }
}
