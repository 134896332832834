@mixin merp-picking-delete-button-styles() {
    .picking-delete-button {
        @include button-tertiary();
    }

    .picking-delete-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-picking-edit-button-styles() {
    .picking-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-picking-open-list-button-styles() {
    .open-picking-list-icon {
        @include button-icon($mi-var-arrow-left); // @todo - change placeholder icon
    }
}

@mixin merp-picking-save-button-styles() {
    .picking-save-button {
        @include button-primary($border-radius: 0);
    }
}

@mixin merp-picking-finish-and-pack-button-styles() {
    .finish-and-pack-button {
        @include button-secondary($border-radius: 0);
    }
}

@mixin merp-picking-button-component-styles() {
    @include merp-picking-delete-button-styles();
    @include merp-picking-edit-button-styles();
    @include merp-picking-open-list-button-styles();
    @include merp-picking-save-button-styles();
    @include merp-picking-finish-and-pack-button-styles();
}
