@mixin merp-product-location-button-component-styles() {
    .location-view-products-icon,
    .view-products-per-location-icon,
    .edit-products-per-location-icon {
        margin-right: $button-icon-right-margin;
    }

    .location-view-products-icon,
    .view-products-per-location-icon {
        @include merp-icon($mi-var-view);
    }

    .edit-products-per-location-icon {
        @include merp-icon($mi-var-pencil);
    }
}
