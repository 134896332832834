$category-tree-color: if(variable-exists('category-tree-color'), $category-tree-color, 'grey-light');
$category-tree-icon-color: if(variable-exists('category-tree-icon-color'), $category-tree-icon-color, 'grey-dark-lighter');

@mixin merp-product-category-list-component-styles() {
    .category-website-name-wrapper {
        @include toolbar($align-items: center);
        border-right: rem-calc(2) $global-border-style get-color('black-pitch');
        background-color: get-color('black');
        padding: rem-calc(10);
        text-transform: uppercase;
        color: get-color('white');
        font-size: rem-calc(12);
        font-weight: get-font-weight('bold');

        .category-website-name {
            flex-grow: 1;
        }
    }

    .category-body {
        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }
    }

    .category-row {
        @include grid-row($column-gutters: ('small': 0));

        &:last-child {
            border-bottom: rem-calc(2) $global-border-style get-color('grey-medium-darker');
        }

        .category-list,
        .category-list-inner {
            position: relative;
            margin-left: 1.5em;
            padding: 0;
            list-style: none;

            &:not(:first-child) {
                &::before {
                    display: block;
                    position: absolute;
                    top: rem-calc(-12);
                    bottom: 0;
                    left: rem-calc(-10);
                    border-left: rem-calc(2) $global-border-style get-color($category-tree-color);
                    width: 0;
                    content: '';
                }
            }
        }

        .category-list-inner {
            margin-left: 1em;
        }

        .category-list {
            &.top-level {
                margin-left: 0;
            }

            .category-list-item {
                position: relative;
                margin: 0;
                padding: 0 1.2em;
                line-height: 2.5em;
                white-space: nowrap;
                font-weight: get-font-weight('normal');

                &::before {
                    display: block;
                    position: absolute;
                    top: 1.2em;
                    left: rem-calc(-10);
                    border-top: rem-calc(2) $global-border-style get-color($category-tree-color);
                    width: 1.8em;
                    height: 0;
                    content: '';
                }

                &:last-child {
                    &::before {
                        bottom: 0;
                        background-color: get-color('white');
                        height: auto;
                    }
                }

                &.top-level {
                    margin-left: 0;
                    padding: 0;
                    font-weight: get-font-weight('bold');

                    &:last-child {
                        &::before {
                            z-index: -1;
                            border-top: 0;
                        }
                    }
                }
            }
        }

        &:nth-child(even) {
            background-color: get-color('blue-light');

            .category-list {
                .category-list-item {
                    &:last-child {
                        ::before {
                            background-color: get-color('blue-light');
                        }
                    }
                }
            }
        }

        .product-category-icon {
            display: inline-block;
            margin-right: $button-icon-right-margin;
            background-color: get-color($category-tree-icon-color);
            width: rem-calc(10);
            height: rem-calc(10);

            &.top-level {
                background-color: get-color('primary');
            }
        }
    }

    .category-column {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 50%
            )
        );
    }
}
