$mi-var-account-settings: '\e030';
$mi-var-arrow-down: '\e000';
$mi-var-arrow-left: '\e002';
$mi-var-arrow-right: '\e001';
$mi-var-arrow-up: '\e003';
$mi-var-backorder: '\e029';
$mi-var-calendar: '\e043';
$mi-var-cancel: '\e005';
$mi-var-catalog: '\e02c';
$mi-var-category: '\e006';
$mi-var-channel: '\e02b';
$mi-var-check-circled-empty: '\e02a';
$mi-var-check-circled: '\e007';
$mi-var-checkbox-empty: '\e040';
$mi-var-checkbox: '\e03b';
$mi-var-circle: '\e009';
$mi-var-close: '\e00a';
$mi-var-content: '\e013';
$mi-var-cost: '\e00b';
$mi-var-credit-cards: '\e044';
$mi-var-current-year: '\e00c';
$mi-var-customers: '\e032';
$mi-var-dollar: '\e00d';
$mi-var-filter: '\e00e';
$mi-var-gear: '\e031';
$mi-var-ideal-price: '\e00f';
$mi-var-info-outline: '\e042';
$mi-var-info: '\e03a';
$mi-var-last-sold: '\e010';
$mi-var-last-year-sales: '\e011';
$mi-var-last-ytd: '\e012';
$mi-var-lock-outline: '\e041';
$mi-var-logout: '\e033';
$mi-var-magento: '\e034';
$mi-var-markup: '\e014';
$mi-var-menu: '\e02f';
$mi-var-minus: '\e015';
$mi-var-more: '\e02e';
$mi-var-note: '\e03e';
$mi-var-notifications: '\e016';
$mi-var-out-going: '\e017';
$mi-var-password: '\e018';
$mi-var-pencil: '\e019';
$mi-var-plus-minus: '\e01b';
$mi-var-plus: '\e01a';
$mi-var-purchasing: '\e03c';
$mi-var-question: '\e02d';
$mi-var-radio-button: '\e008';
$mi-var-receiving: '\e01c';
$mi-var-return: '\e01d';
$mi-var-sales: '\e036';
$mi-var-save: '\e01e';
$mi-var-search: '\e01f';
$mi-var-selling-price: '\e020';
$mi-var-shipping-truck: '\e021';
$mi-var-sort: '\e022';
$mi-var-special-price: '\e023';
$mi-var-speech-bubble: '\e03f';
$mi-var-switch-user: '\e024';
$mi-var-switch: '\e039';
$mi-var-sync: '\e025';
$mi-var-system: '\e03d';
$mi-var-transfer: '\e026';
$mi-var-trash: '\e027';
$mi-var-undo: '\e004';
$mi-var-user: '\e037';
$mi-var-vendor: '\e038';
$mi-var-view: '\e028';
$mi-var-warning: '\e035';
