@mixin merp-receiving-button-component-styles() {
    .receiving-edit-icon {
        @include button-icon($mi-var-pencil);
    }

    .receive-purchaseorder-icon {
        @include button-icon($mi-var-receiving);
    }

    .post-to-inventory-icon {
        @include button-icon($mi-var-catalog);
    }

    .post-to-inventory-button {
        @include button-secondary();
    }
}
