@mixin merp-sales-order-titlebar-styles() {
    .order-titlebar-icon {
        @include titlebar-icon($mi-var-sales);
    }
}

@mixin merp-sales-payment-titlebar-styles() {
    .payment-titlebar-icon {
        @include titlebar-icon($mi-var-dollar);
    }
}

@mixin merp-sales-invoice-titlebar-styles() {
    .invoice-titlebar-icon {
        @include titlebar-icon($mi-var-note);
    }
}

@mixin merp-sales-creditmemo-titlebar-styles() {
    .creditmemo-titlebar-icon {
        @include titlebar-icon($mi-var-note);
    }
}

@mixin merp-sales-titlebar-component-styles() {
    @include merp-sales-order-titlebar-styles();
    @include merp-sales-payment-titlebar-styles();
    @include merp-sales-invoice-titlebar-styles();
    @include merp-sales-creditmemo-titlebar-styles();
}
