@mixin merp-fieldset-component-styles() {
    .fieldset-legend {
        @include toolbar($align-items: center);

        .form-field {
            flex-basis: auto;
            margin: 0;
        }
    }

    .fieldset-legend-title {
        @include kindling-typography-h5-margin($last-child-no-margin: false);
        flex-grow: 1;
        z-index: 9999;
    }
}
