@mixin merp-core-button-component-styles() {
    .account-change-password-icon {
        @include merp-icon($mi-var-password);
        margin-right: $button-icon-right-margin;
    }

    .account-edit-icon {
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
    }

    .account-view-icon {
        @include merp-icon($mi-var-view);
        margin-right: $button-icon-right-margin;
    }

    .account-change-password-button {
        @include button-secondary();

        .title-bar-button-group &,
        .grid-title-bar-button-group &,
        .form-title-bar-button-group & {
            border-radius: 0;
        }
    }

    .permission-description-toggle {
        @include button-style(
            $background-color: transparentize(get-color('white'), 1),
            $text-color: 'primary',
            $text-color-hover: 'blue-dark'
        );
        @include merp-icon($mi-var-info-outline);
        padding: 0;
        vertical-align: middle;
        font-size: rem-calc(18);
    }
}
