@mixin merp-purchaseorder-address-component-styles() {
    .purchase-order-address-view {
        border: rem-calc(2) $global-border-style get-color('grey-light');

        @each $breakpoint, $padding in $global-padding {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }

        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }
}
