@mixin merp-vendor-address-list-component-styles() {
    .vendor-addresses {
        @include box-padding(2);
        background-color: get-color('grey-lightest');
    }

    .vendor-address-top {
        display: flex;

        .vendor-address-heading {
            margin-top: 0;
        }

        .vendor-address-buttons {
            margin-left: auto;

            .vendor-address-add {
                @include button-primary();
            }

            .vendor-address-add-icon {
                @include merp-icon($mi-var-plus);
                margin-right: $button-icon-right-margin;
            }
        }
    }

    .vendor-address-table {
        @include address-table();

        .vendor-address-item {
            @include address-item();

            &.vendor-address-inactive {
                .vendor-address-item-head,
                .vendor-address {
                    background-color: get-color('grey-light');
                }
            }

            .vendor-address-item-head {
                @include address-item-header();

                .vendor-address-label {
                    font-weight: get-font-weight('bold');

                    @each $breakpoint, $font-size in get-font-size('h5') {
                        @include breakpoint($breakpoint) {
                            font-size: $font-size;
                        }
                    }
                }

                .vendor-address-button-group {
                    margin-left: auto;
                }
            }

            .vendor-address {
                @include address-item-body();
            }
        }
    }
}
