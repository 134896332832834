$topbar-background-color: 'white';
$topbar-border-width: if(variable-exists('topbar-border-width'), $topbar-border-width, rem-calc(2));
$topbar-border-style: if(variable-exists('topbar-border-style'), $topbar-border-style, $global-border-style);
$topbar-border-color: if(variable-exists('topbar-border-color'), $topbar-border-color, $global-border-color);
$dropdown-triangle-left: 85%;
$dropdown-triangle-height: rem-calc(8);
$dropdown-triangle-width: rem-calc(10);
$dropdown-link-padding: (
    'small': rem-calc(10)
);

@mixin merp-top-bar-component-styles() {
    .top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: $topbar-border-width $topbar-border-style get-color($topbar-border-color);
        background-color: get-color($topbar-background-color);

        @each $breakpoint, $height in $topbar-heights {
            @include breakpoint($breakpoint) {
                height: $height;
            }
        }
    }

    .top-bar-title {
        flex: 1 0 auto;
        text-align: center;
    }

    .top-bar-logo {
        @include button-style-base();
        @include button-size();
        margin: 0;
        line-height: 1;
        font-size: rem-calc(32);

        @each $breakpoint, $height in $topbar-heights {
            @include breakpoint($breakpoint) {
                height: ($height - $topbar-border-width);
            }
        }
    }

    .nav-toggle-main-menu,
    .nav-toggle-user-dropdown {
        @include button-size();
        background-color: get-color($topbar-background-color);
        cursor: pointer;

        @each $breakpoint, $height in $topbar-heights {
            @include breakpoint($breakpoint) {
                height: ($height - $topbar-border-width);
            }
        }
    }

    .nav-toggle-user-dropdown {
        .nav-toggle-user-dropdown-icon {
            @include merp-icon($mi-var-user);
            @include merp-icon($mi-var-account-settings, false) {
                display: inline-block;
                margin-left: -0.4em;
                vertical-align: top;
                color: get-color('primary');
                font-size: 0.8em;
            }
            position: relative;
            font-size: 2.1rem;
        }

        &:focus,
        &:hover {
            .nav-toggle-user-dropdown-icon {
                &::after {
                    animation: get-duration('spin') linear infinite spin;
                }
            }
        }
    }

    .user-dropdown {
        @include dropdown($include-padding: false);
        border-radius: rem-calc(5);

        .user-dropdown-link {
            @include dropdown-link();
            display: flex;
            align-items: center;
            line-height: 1;

            @each $breakpoint, $padding in $dropdown-link-padding {
                @include breakpoint($breakpoint) {
                    padding: $padding ($padding * 2);
                }
            }
        }

        .user-dropdown-list-item {
            @include dropdown-list-item();

            &:first-of-type {
                .user-dropdown-link {
                    @each $breakpoint, $padding in $dropdown-link-padding {
                        padding-top: ($padding * 2);
                    }
                }
            }

            &:last-of-type {
                .user-dropdown-link {
                    @each $breakpoint, $padding in $dropdown-link-padding {
                        padding-bottom: ($padding * 2);
                    }
                }
            }
        }

        .link-description {
            @include dropdown-link-description();
            color: get-color('grey-dark-lighter');
        }

        .notifications-count {
            margin-left: auto;
            border-radius: rem-calc(15);
            background-color: get-color('green');
            padding: 0.3em 0.7em;
            color: get-color('white');
        }

        .user-actions-account-icon,
        .user-actions-notifications-icon,
        .user-actions-logout-icon {
            margin-right: ($button-icon-right-margin * 2);
            color: get-color('primary');
            font-size: 160%;
        }

        .user-actions-account-icon {
            @include merp-icon($mi-var-user);
        }

        .user-actions-notifications-icon {
            @include merp-icon($mi-var-notifications);
        }

        .user-actions-logout-icon {
            @include merp-icon($mi-var-logout);
        }
    }

    .nav-toggle-main-menu {
        @include transition(transform);
        perspective: rem-calc(140);

        @include breakpoint($responsive-menu-breakpoint) {
            display: none;
        }

        .menu-icon {
            @include merp-icon($mi-var-arrow-left) {
                @include transition(transform, 'slow');
                display: block;
                position: absolute;
                top: 50%;
                left: rem-calc(-10);
                transform: rotateY(0deg) translateY(-50%);
                color: get-color('primary');
                font-size: rem-calc(20);
                transform-origin: rem-calc(35) 0;
            }
            display: inline-block;
            position: relative;
            width: rem-calc(50);
        }

        &:hover,
        &:focus {
            .menu-icon {
                &::before {
                    transform: rotateY(180deg) translateY(-50%);
                }
            }
        }

        &.is-open {
            .menu-icon {
                &::before {
                    transform: rotateY(180deg) translateY(-50%);
                }
            }

            @each $breakpoint, $width in $main-menu-widths {
                @include breakpoint($breakpoint) {
                    transform: translateX($width);
                }
            }

            @include breakpoint($responsive-menu-breakpoint) {
                transform: none;
            }

            &:hover,
            &:focus {
                .menu-icon {
                    &::before {
                        transform: rotateY(0deg) translateY(-50%);
                    }
                }
            }
        }
    }

    .toolbar-delete-button {
        @include button-secondary();
    }

    .toolbar-delete-button-icon {
        @include button-icon($mi-var-trash);
    }
}
