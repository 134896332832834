@mixin merp-location-button-styles() {
    .location-settings-icon {
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
    }

    .location-settings-button {
        @include button-primary();
    }

    .location-view-products-button {
        @include button-secondary();
    }
}
