@mixin merp-config-section-component-styles() {
    .config-sections {
        margin: 0;

        > li {
            margin: 0;
            list-style: none;

            > a {
                display: block;
                padding: rem-calc(15);
                width: 100%;
                color: get-color('black');
            }

            &.is-active > a {
                background-color: get-color('white');

                @each $breakpoint, $border-width in $global-border-width {
                    @include breakpoint($breakpoint) {
                        border-bottom: rem-calc($border-width) $global-border-style get-color('blue');
                    }
                }
            }

            &:not(.is-active) > a {
                @each $breakpoint, $border-width in $global-border-width {
                    @include breakpoint($breakpoint) {
                        border-bottom: rem-calc($border-width) $global-border-style get-color($global-border-color);
                    }
                }

                &:hover,
                &:focus {
                    background-color: get-color('white');

                    @each $breakpoint, $border-width in $global-border-width {
                        @include breakpoint($breakpoint) {
                            border-bottom: rem-calc($border-width) $global-border-style get-color('blue');
                        }
                    }
                }
            }
        }
    }
}
