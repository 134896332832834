@mixin merp-datepicker-component-styles() {
    .ui-icon-circle-triangle-w {
        @include merp-icon($mi-var-arrow-left);
    }

    .ui-icon-circle-triangle-e {
        @include merp-icon($mi-var-arrow-right, false);
    }

    .ui-datepicker-header {
        display: flex;
        flex-wrap: wrap;
        background-color: get-color('white');

        .ui-datepicker-prev,
        .ui-datepicker-next {
            padding: rem-calc(10 5 0);

            &,
            &:hover,
            &:focus {
                color: get-color('black');
            }
        }

        .ui-datepicker-prev {
            margin-right: auto;
        }

        .ui-datepicker-title {
            @include heading-styles-h5();
            flex-basis: 100%;
            text-align: center;
        }
    }

    .ui-datepicker-calendar {
        td {
            &:hover {
                background-color: transparentize(get-color('blue'), 0.5);
            }
        }
    }

    .ui-datepicker-today {
        background-color: transparentize(get-color('blue'), 0.6);
        font-weight: get-font-weight('bold');
    }
}
