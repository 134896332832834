@mixin merp-customer-address-list-component-styles() {
    @include customer-address-button-styles();

    .customer-addresses {
        @include box-padding(2);
        background-color: get-color('grey-lightest');
    }

    .customer-address-top {
        display: flex;
    }

    .customer-address-table {
        @include address-table();

        .customer-address-item {
            @include address-item();

            &.customer-address-inactive {
                .customer-address-item-head,
                .customer-address {
                    background-color: get-color('grey-light');
                }
            }

            .customer-address-item-head {
                @include address-item-header();

                .customer-address-label {
                    font-weight: get-font-weight('bold');

                    @each $breakpoint, $font-size in get-font-size('h5') {
                        @include breakpoint($breakpoint) {
                            font-size: $font-size;
                        }
                    }
                }

                .customer-address-button-group {
                    margin-left: auto;
                }
            }

            .customer-address {
                @include address-item-body();
            }
        }
    }
}
