@mixin merp-packing-delete-button-styles() {
    .packing-delete-button {
        @include button-tertiary();
    }

    .packing-delete-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-packing-print-pdf-button-styles() {
    .packing-print-pdf-icon {
        @include button-icon($mi-var-sync); // @todo update placeholder
    }
}

@mixin merp-packing-open-packing-list-button-styles() {
    .open-packing-list-icon {
        @include button-icon($mi-var-arrow-left);
    }
}

@mixin merp-packing-view-button-styles() {
    .packing-view-button {
        @include button-primary($button-size: 'small');
    }
}

@mixin merp-packing-finish-button-styles() {
    .packing-finish-button {
        @include button-primary();
    }
}

@mixin merp-packing-next-button-styles() {
    .packing-next-button {
        @include button-secondary();
    }
}

@mixin merp-packing-add-boxes-button-styles() {
    .packing-add-boxes-button {
        @include button-primary($button-size: 'large');
    }

    .packing-add-boxes-button-plus {
        @include button-icon($mi-var-plus);
    }
}

@mixin merp-packing-box-delete-button-styles() {
    .packing-box-delete-button {
        @include button-primary();
    }

    .packing-box-delete-button-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-packing-edit-button-styles() {
    .packing-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-packing-remove-from-assignment-button-styles() {
    .packing-remove-from-assignment-button {
        @include button-secondary($border-radius: 0);
    }

    .packing-remove-from-assignment-icon {
        @include button-icon($mi-var-cancel);
    }
}

@mixin merp-packing-start-button-styles() {
    .packing-start-icon {
        @include button-icon($mi-var-sync);
    }
}

@mixin merp-unpack-edit-button-styles() {
    .unpack-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-open-unpack-list-button-styles() {
    .open-unpack-list-icon {
        @include button-icon($mi-var-arrow-left);
    }
}

@mixin merp-unpack-save-button-styles() {
    .unpack-save-button {
        @include button-primary($border-radius: 0);
    }
}

@mixin merp-packing-button-component-styles() {
    @include merp-packing-delete-button-styles();
    @include merp-packing-print-pdf-button-styles();
    @include merp-packing-open-packing-list-button-styles();
    @include merp-packing-view-button-styles();
    @include merp-packing-finish-button-styles();
    @include merp-packing-next-button-styles();
    @include merp-packing-add-boxes-button-styles();
    @include merp-packing-box-delete-button-styles();
    @include merp-packing-remove-from-assignment-button-styles();
    @include merp-packing-start-button-styles();
    @include merp-unpack-edit-button-styles();
    @include merp-open-unpack-list-button-styles();
    @include merp-unpack-save-button-styles();
}
