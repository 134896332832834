$responsive-menu-breakpoint: if(variable-exists('responsive-menu-breakpoint'), $responsive-menu-breakpoint, 'large');

@mixin merp-empty-layout-styles() {
    .page-layout-empty {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        .top-bar {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: get-z-index('top-bar');

            @each $breakpoint, $height in $topbar-heights {
                @include breakpoint($breakpoint) {
                    height: $height;

                    ~ .page-wrapper {
                        margin-top: $height;
                    }
                }
            }

            @include breakpoint($responsive-menu-breakpoint) {
                @each $breakpoint, $width in $main-menu-widths {
                    @include breakpoint($breakpoint) {
                        left: $width;
                    }
                }
            }
        }

        .main-menu {
            @include transition(transform);
            position: fixed;
            top: 0;
            right: 100%;
            bottom: 0;
            z-index: get-z-index('main-menu');

            @each $breakpoint, $width in $main-menu-widths {
                @include breakpoint($breakpoint) {
                    width: $width;
                }
            }

            &.is-open {
                transform: translateX(100%);

                @include breakpoint($responsive-menu-breakpoint) {
                    transform: none;
                }
            }

            @include breakpoint($responsive-menu-breakpoint) {
                right: auto;
                left: 0;

                ~ .page-wrapper {
                    @each $breakpoint, $width in $main-menu-widths {
                        @include breakpoint($breakpoint) {
                            margin-left: $width;
                        }
                    }
                }
            }
        }

        &.has-data-grid-cart {
            .page-wrapper {
                @include breakpoint($data-grid-cart-responsive-breakpoint) {
                    margin-right: $data-grid-cart-width;
                }
            }
        }

        .page-wrapper {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }

        .page-main {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }

        &:not(.page-main-no-padding) {
            .page-main {
                @each $breakpoint, $padding in $grid-column-gutters {
                    @include breakpoint($breakpoint) {
                        padding: $padding;
                    }
                }
            }
        }
    }
}
