$address-table-gutter: if(variable-exists('address-table-gutter'), $address-table-gutter,
    (
        'small': rem-calc(16),
        'large': rem-calc(32)
    )
);

$address-table-item-columns: if(variable-exists('address-table-item-columns'), $address-table-item-columns,
    (
        'small': 100%,
        'medium': 100% / 2,
        'large': 100% / 3,
        'xlarge': 100% / 4,
        'xxlarge': 100% / 5
    )
);

@mixin address-table($gutters: $address-table-gutter) {
    @include grid-row(
        $column-gutters: $gutters,
        $vertical-gutters: true
    );
}

@mixin address-item($responsive-sizes: $address-table-item-columns) {
    @include box-container();
    @include grid-column(
        $responsive-sizes: $responsive-sizes,
        $flex-grow: 0,
        $flex-shrink: 0
    );
}

@mixin address-item-header() {
    @include box-section(
        $background-color: 'white',
        $border-sides: ('top', 'right', 'left'),
        $border-width: ('small': rem-calc(2)),
        $border-style: solid,
        $border-color: 'grey-light'
    );
    display: flex;

    @each $breakpoint, $padding in $box-padding {
        @include breakpoint($breakpoint) {
            padding-bottom: 0;
        }
    }
}

@mixin address-item-body() {
    @include box-section(
        $background-color: 'white',
        $border-sides: ('right', 'bottom', 'left'),
        $border-width: ('small': rem-calc(2)),
        $border-style: solid,
        $border-color: 'grey-light'
    );
    flex-grow: 1;

    @each $breakpoint, $padding in $box-padding {
        @include breakpoint($breakpoint) {
            padding-top: 0;
        }
    }
}
