@mixin merp-statistics-component-styles() {
    .statistics-container {
        border: rem-calc(2) $global-border-style get-color($global-border-color);

        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }
    }

    .statistics-statistic {
        flex: 1 0 auto;
    }

    .primary-statistics {
        display: flex;

        @each $breakpoint, $margin in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }

    .statistics-statistic-value {
        color: get-color('primary');
        font-weight: get-font-weight('bold');

        @each $breakpoint, $font-size in get-font-size('h1') {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
            }
        }
    }

    .statistics-statistic-title {
        display: flex;
        align-items: center;
    }

    .statistics-title-icon {
        margin-right: 0.5rem;
        color: get-color('primary');
        font-size: 120%;
    }

    .statistics-icon-sales {
        @include merp-icon($mi-var-dollar);
    }

    .statistics-icon-orders {
        @include merp-icon($mi-var-shipping-truck);
    }

    .statistics-icon-day {
        @include merp-icon($mi-var-calendar);
    }

    .statistics-statistic-title-label {
        text-transform: uppercase;
        letter-spacing: rem-calc(1);
        font-weight: get-font-weight('bold');
    }

    .statistics-table {
        @include table(
            $background-color: 'white',
            $border-style: 'none',
            $head-background-color: 'white',
            $head-font-color: 'black'
        );

        thead {
            border-top: $table-border-width $table-border-style get-color($global-border-color);
            border-bottom: $table-border-width $table-border-style get-color($global-border-color);
            text-transform: uppercase;
            letter-spacing: rem-calc(1);

            .statistics-table-icon {
                font-size: 120%;
            }
        }

        tbody {
            > tr {
                > td {
                    vertical-align: middle;
                }
            }
        }

        .statistics-table-value {
            font-size: 150%;
            font-weight: get-font-weight('bold');
        }
    }

    .statistics-sidebar-statistic {
        display: flex;
        align-items: center;
        border-top: rem-calc(2) $global-border-style get-color('grey-medium-darker');

        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: ($padding / 2) 0;
            }
        }
    }

    .statistics-sidebar-statistic-icon {
        flex: 0 0 auto;
        margin-right: rem-calc(20);
        font-size: 250%;
    }

    .statistics-sidebar-statistic-content {
        flex: 1 1 auto;
    }

    .statistics-sidebar-statistic-value {
        line-height: 1.1;
        font-size: 150%;
        font-weight: get-font-weight('bold');
    }

    .statistics-sidebar-statistic-label {
        text-transform: uppercase;
        font-size: rem-calc(10);
    }

    .statistics-icon-balance-due {
        @include merp-icon($mi-var-credit-cards);
        color: get-color('red');
    }

    .statistics-icon-last-sold {
        @include merp-icon($mi-var-last-sold);
        color: get-color('green');
    }

    .statistics-icon-current-year-sales {
        @include merp-icon($mi-var-current-year);
        color: get-color('blue');
    }

    .statistics-icon-last-ytd-sales {
        @include merp-icon($mi-var-last-ytd);
        color: get-color('purple');
    }

    .statistics-icon-last-year-sales {
        @include merp-icon($mi-var-last-year-sales);
        color: get-color('teal');
    }

    .statistics-icon-current-year-sold {
        @include merp-icon($mi-var-current-year);
        color: get-color('blue');
    }

    .statistics-icon-last-year-sold {
        @include merp-icon($mi-var-last-ytd);
        color: get-color('purple');
    }

    .statistics-icon-outgoing {
        @include merp-icon($mi-var-out-going);
        color: get-color('green');
    }

    .statistics-icon-backordered {
        @include merp-icon($mi-var-backorder);
        color: get-color('red');
    }
}
