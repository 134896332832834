$item-search-border-width: if(variable-exists('item-search-border-width'), $item-search-border-width, rem-calc(3));
$item-search-border-color: if(variable-exists('item-search-border-color'), $item-search-border-color, 'grey-medium');

@mixin merp-item-table-component-styles() {
    .item-table-heading {
        @include toolbar($align-items: center);

        > * {
            flex: 1 0 auto;
        }

        .item-table-actions {
            text-align: right;
        }

        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-top: $margin / 2;
                margin-bottom: $margin / 2;
            }
        }
    }

    .item-table {
        @include item-table();

        td {
            vertical-align: middle;

            #{text-inputs()},
            select,
            textarea {
                background-color: transparent;
            }

            .form-field {
                margin-bottom: 0;
            }

            &.contains-input {
                padding: 0;

                .form-field {
                    margin-bottom: 0;
                }
            }

            input[type='number'] {
                &.ajax-number-input {
                    appearance: textfield;
                }
            }
        }

        .item-row-allocate-button,
        .item-row-delete-button,
        .item-row-edit-button,
        .item-row-adjust-button,
        .item-row-add-button,
        .item-row-view-button {
            @include button-white-blue-hover('small');

            .item-row-trash-icon {
                @include merp-icon($mi-var-trash);
                margin-right: $button-icon-right-margin;
            }

            .item-row-allocate-icon {
                @include merp-icon($mi-var-receiving);
                margin-right: $button-icon-right-margin;
            }

            .item-row-edit-icon {
                @include merp-icon($mi-var-pencil);
                margin-right: $button-icon-right-margin;
            }

            .item-row-adjust-icon {
                @include merp-icon($mi-var-sync);
                margin-right: $button-icon-right-margin;
            }

            .item-row-add-icon {
                @include merp-icon($mi-var-plus);
                margin-right: $button-icon-right-margin;
            }

            .item-row-view-icon {
                @include merp-icon($mi-var-view);
                margin-right: $button-icon-right-margin;
            }
        }

        .item-search-row {
            border-top: $item-search-border-width $global-border-style get-color($item-search-border-color);
            border-bottom: $item-search-border-width $global-border-style get-color($item-search-border-color);
            background-color: get-color('blue-lightest');

            .item-search {
                padding: 0;
                overflow: hidden;

                .item-search-icon {
                    @include merp-icon($mi-var-search);
                    margin-right: $button-icon-right-margin;
                }
            }

            .item-search-qty {
                @include grid-row();
                background-color: get-color('blue-light');

                .item-search-label {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': 25%,
                        ),
                        $flex-grow: 0,
                        $flex-shrink: 0
                    );
                    align-self: center;
                    background-color: get-color('blue-light');
                    text-align: center;
                    text-transform: uppercase;
                }

                .item-search-input {
                    @include grid-column(
                        $responsive-sizes: (
                            'small': 55%,
                        ),
                        $flex-grow: 0,
                        $flex-shrink: 0
                    );
                    background-color: get-color('white');
                }

                .item-search-button {
                    @include button-style($background-color: 'blue', $border-radius: 0);
                    @include grid-column(
                        $responsive-sizes: (
                            'small': 20%,
                        ),
                        $flex-grow: 0,
                        $flex-shrink: 0
                    );
                    padding: 0;

                    .item-add-icon {
                        @include merp-icon($mi-var-plus);
                        align-self: center;
                    }
                }
            }
        }

        .totals-label {
            border-right: rem-calc(2) $global-border-style get-color('white');
            text-align: right;

            @each $breakpoint, $padding in $table-padding {
                @include breakpoint($breakpoint) {
                    padding: ($padding / 2) $padding;
                }
            }
        }

        .total-row {
            border-top: rem-calc(2) $global-border-style get-color('white');
            font-size: rem-calc(20);

            td {
                @each $breakpoint, $padding in $table-padding {
                    @include breakpoint($breakpoint) {
                        padding: $padding;
                    }
                }
            }
        }
    }
}
