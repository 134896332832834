@mixin merp-core-titlebar-component-styles() {
    .user-titlebar-icon {
        @include titlebar-icon($mi-var-user);
    }

    .account-titlebar-icon {
        @include merp-icon($mi-var-user);
        margin-right: $button-icon-right-margin;
        vertical-align: -15%;
        color: get-color('primary');
        font-size: 150%;
    }
}
