$location-border-width: if(variable-exists('location-border-width'), $location-border-width, rem-calc(4));
$location-border-style: if(variable-exists('location-border-style'), $location-border-style, $global-border-style);
$location-border-color: if(variable-exists('location-border-color'), $location-border-color, 'primary');

@mixin merp-location-switcher-component-styles() {
    .location-switcher {
        @include toolbar($align-items: stretch);
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: get-font-weight('bold');

        .location-name {
            flex-grow: 1;
            margin-top: rem-calc(-$location-border-width);
            border-top: $location-border-width $location-border-style get-color($location-border-color);
            color: get-color('primary');

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    padding: (($margin - $location-border-width) / 2) $margin;
                }
            }

            @include breakpoint('medium') {
                flex-grow: 0;
            }
        }

        .location-select {
            flex: 1 0 auto;
            border-bottom: rem-calc(1) $global-border-style get-color($global-border-color);
            border-left: rem-calc(1) $global-border-style get-color($global-border-color);
            background-color: get-color('grey-lightest');

            .form-field {
                margin-bottom: 0;
            }

            .location-switcher-select {
                @include form-select();
                border: 0;
                background-color: get-color('grey-lightest');
                width: auto;
                min-width: rem-calc(320);
            }
        }
    }
}
