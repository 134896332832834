@mixin merp-packing-add-boxes-component-styles() {
    .packing-edit-add-boxes {
        @include box-padding();
        background-color: get-color('grey-light');

        form {
            display: flex;
            align-items: center;
        }

        .form-field {
            margin-right: rem-calc(15);
            margin-bottom: 0;
        }
    }
}
