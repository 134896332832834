@mixin merp-feedback-item-component-styles() {
    .feedback-item {
        @each $breakpoint, $border-width in $global-border-width {
            @include breakpoint($breakpoint) {
                border: rem-calc($border-width) $global-border-style get-color($global-border-color);
            }
        }

        margin-top: rem-calc(12px);

        > div {
            padding: rem-calc(12px);
        }

        &:last-child {
            @each $breakpoint, $margin in $grid-column-gutters {
                @include breakpoint($breakpoint) {
                    margin-bottom: $margin;
                }
            }
        }
    }

    .feedback-item-header {
        display: flex;
        flex-wrap: wrap;
        background-color: get-color('grey-medium');

        .feedback-item-header-information {
            flex: 9999;
        }

        .feedback-item-header-actions {
            flex: 1;

            > a {
                @include button-primary('small');
            }
        }
    }

    .feedback-message {
        background-color: get-color('grey-lightest');

        @each $breakpoint, $border-width in $global-border-width {
            @include breakpoint($breakpoint) {
                border-top: rem-calc($border-width) $global-border-style get-color($global-border-color);
                border-bottom: rem-calc($border-width) $global-border-style get-color($global-border-color);
            }
        }
    }

    .feedback-item-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: get-color('grey-medium');
    }
}
