@mixin merp-customer-notes-component-styles() {
    .customer-notes-heading {
        margin: 0;
        border-bottom: rem-calc(3) solid get-color('grey-medium');
        padding-bottom: rem-calc(16);
    }

    .customer-notes-item {
        padding-top: rem-calc(16);

        .customer-notes-header {
            display: flex;
            align-items: center;

            .customer-notes-edit,
            .customer-notes-save {
                @include button-primary('small');
                margin-left: auto;
            }

            .customer-notes-edit {
                @include merp-icon($mi-var-pencil);
                margin-right: $button-icon-right-margin;
            }

            .customer-notes-save {
                @include merp-icon($mi-var-save);
                margin-right: $button-icon-right-margin;
            }
        }

        .customer-notes-content,
        .customer-notes-form {
            border-bottom: rem-calc(2) solid;
            padding-bottom: rem-calc(16);
        }

        .customer-notes-content {
            border-bottom-color: get-color('grey-medium');
        }

        .customer-notes-form {
            border-bottom-color: get-color('primary');

            .customer-notes-label {
                color: get-color('primary');
            }
        }
    }
}
