@mixin merp-config-tab-accordion-component-styles() {
    .config-tab-accordion {
        @include accordion-container();
        background-color: get-color('grey-lightest');

        @each $breakpoint, $border-width in $global-border-width {
            @include breakpoint($breakpoint) {
                border: rem-calc($border-width) $global-border-style get-color($global-border-color);
            }
        }
    }

    .config-tab-accordion-item {
        @include accordion-item();

        &.is-active {
            > .config-tab-accordion-title {
                background-color: get-color('blue');
                color: get-color('white');
            }
        }
    }

    .config-tab-accordion-title {
        @include accordion-title($background-color: get-color('blue-light'), $font-weight: 'bold', $justify-content: 'left');
    }

    .config-tab-accordion-content {
        @include accordion-body($color: get-color('black'));
    }
}
