$data-grid-cart-responsive-breakpoint: if(variable-exists('data-grid-cart-responsive-breakpoint'), $data-grid-cart-responsive-breakpoint, 'xxlarge');
$data-grid-flyout-width: if(variable-exists('data-grid-flyout-width'), $data-grid-flyout-width, rem-calc(300));
$data-grid-cart-width: if(variable-exists('data-grid-cart-width'), $data-grid-cart-width, $data-grid-flyout-width);

@mixin data-grid-flyout() {
    @include box-container($margin-ratio: 0);
    @include transition(transform);
    position: fixed;
    bottom: 0;
    left: 100%;
    z-index: get-z-index('data-grid-flyout');
    border-left: rem-calc(2) $global-border-style get-color($global-border-color);
    width: $data-grid-flyout-width;

    @each $breakpoint, $height in $topbar-heights {
        @include breakpoint($breakpoint) {
            top: $height;
        }
    }

    &.is-open {
        transform: translateX(-100%);
    }
}

@mixin data-grid-flyout-header() {
    flex: 0 0 auto;
    background: get-color('grey-light');
    padding: rem-calc(10 0);
    text-align: center;
}

@mixin data-grid-cart() {
    width: $data-grid-cart-width;

    @include breakpoint($data-grid-cart-responsive-breakpoint) {
        right: 0;
        left: auto;

        &,
        &.is-open {
            transform: none;
        }
    }
}

@mixin merp-data-grid-component-styles() {
    .data-grid-container {
        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: $padding;
            }
        }
    }

    .data-grid-header {
        display: flex;
    }

    .data-grid-title-buttons {
        margin-left: auto;
    }

    .data-grid-new-button {
        @include button-primary();
    }

    .data-grid-new-icon {
        @include merp-icon($mi-var-plus);
        margin-right: $button-icon-right-margin;
    }

    .data-grid-back-icon {
        @include merp-icon($mi-var-return);
        margin-right: $button-icon-right-margin;
    }

    .data-grid-pager {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        > .data-grid-pagination {
            &:not(:first-child) {
                border-left: rem-calc(2) solid get-color($global-border-color);
            }

            &:not(:last-child) {
                padding-right: $grid-actions-margin;
            }
        }
    }

    .data-grid-pagination {
        display: flex;
        align-items: baseline;
        padding-left: $grid-actions-margin;
    }

    .data-grid-pagination-item {
        flex: 1 0 auto;
    }

    .data-grid-actions {
        display: flex;
        margin: $grid-actions-margin 0;
    }

    .data-grid-pagination-button {
        @include button-white('small');
        display: flex;
        align-items: center;
        margin-right: $grid-actions-margin;
        margin-left: $grid-actions-margin;
        border: rem-calc(2) $global-border-style get-color($global-border-color);
    }

    .data-grid-pagination-button-disabled {
        cursor: default;
        color: get-color('grey-dark');
        pointer-events: none;
    }

    .data-grid-pagination-icon-prev {
        @include merp-icon($mi-var-arrow-left);
        margin-right: $button-icon-right-margin;
    }

    .data-grid-pagination-icon-next {
        @include merp-icon($mi-var-arrow-right);
        margin-right: $button-icon-right-margin;
    }

    .data-grid-pagination-input {
        @include button-size('small');
        margin: 0 $grid-actions-margin;
        border: rem-calc(2) $global-border-style get-color($global-border-color);
        border-radius: $button-border-radius;
        width: auto;
    }

    .data-grid-headings {
        a {
            color: inherit;
        }
    }

    .data-grid-edit-button,
    .data-grid-view-button,
    .data-grid-action-button {
        @include button-white-blue-hover('small');
    }

    .data-grid-filter-actions {
        @include button-group($bottom-margin-ratio: 0);
        margin-left: auto;
        border-radius: $button-border-radius;
        overflow: hidden;
    }

    .data-grid-customization-container,
    .data-grid-filter-container {
        @include data-grid-flyout();
    }

    .data-grid-filter-columns-button,
    .data-grid-customize-columns-button {
        @include button-primary($border-radius: 0);
    }

    .data-grid-filter-header,
    .data-grid-customization-header,
    .data-grid-filter-footer,
    .data-grid-customization-footer {
        @include box-section($padding-ratio: 0);
        @include button-group($bottom-margin-ratio: 0);
        flex: 0 0 auto;
    }

    .data-grid-filter-body,
    .data-grid-customization-body {
        @include kindling-form();
        position: relative;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .data-grid-filter-body {
        @include box-section();
    }

    .data-grid-customization-body {
        @include box-section($padding-ratio: 0);
    }

    .data-grid-customization-input {
        @include box-padding();
        margin: 0;
        border-bottom: rem-calc(2) solid get-color($global-border-color);
    }

    .data-grid-filter-title,
    .data-grid-customization-title,
    .data-grid-reset-filter-button,
    .data-grid-customization-save-button,
    .data-grid-customization-reset-button {
        @include button-primary($border-radius: 0);
        @include button-group-item();
    }

    .data-grid-filter-close-icon,
    .data-grid-customization-close-icon {
        @include merp-icon($mi-var-close) {
            margin-right: 0.5em;
        }
    }

    .data-grid-customization-toggle-all {
        @include button-primary($border-radius: 0);

        input[type='checkbox'] {
            @include form-checkbox($checked-color: inherit);
        }
    }

    .data-grid-customization-save-icon {
        @include merp-icon($mi-var-save) {
            margin-right: $button-icon-right-margin;
        }
    }

    .data-grid-customization-reset-icon {
        @include merp-icon($mi-var-plus-minus) {
            margin-right: $button-icon-right-margin;
        }
    }

    .data-grid-horizontal-scroll {
        overflow-x: auto;

        > table {
            > thead,
            > tfoot,
            > tbody {
                > tr {
                    background-color: inherit;

                    > th,
                    > td {
                        &.data-grid-action-column {
                            position: sticky;
                            right: rem-calc(-1);
                            z-index: 1;
                            background-color: inherit;
                        }
                    }
                }
            }
        }
    }

    .data-grid-massaction-actions {
        @include button-group($bottom-margin-ratio: 0);
        border-radius: $button-border-radius;
        overflow: hidden;
    }

    .data-grid-massaction-button {
        @include button-primary($border-radius: 0);
    }

    .data-grid-massaction-checkbox {
        line-height: 1;
        font-size: rem-calc(16);

        input[type='checkbox'] {
            @include form-checkbox();
        }
    }
}
