@mixin merp-customer-button-component-styles() {
    .customer-view-orders-button {
        @include button-secondary();
    }

    .customer-edit-icon {
        @include button-icon($mi-var-pencil);
    }

    .customer-address-delete-button-icon,
    .customer-attribute-delete-button-icon,
    .customer-group-delete-button-icon {
        @include button-icon($mi-var-trash);
    }

    .customer-view-orders-icon {
        @include button-icon($mi-var-pencil);
    }

    .customer-view-revisions-icon {
        @include button-icon($mi-var-view);
    }

    .back-to-customer-revisions-icon {
        @include button-icon($mi-var-return);
    }
}

@mixin customer-address-button-styles() {
    .customer-address-buttons {
        margin-left: auto;
    }

    .customer-address-add {
        @include button-primary();
    }

    .customer-address-add-icon {
        @include button-icon($mi-var-plus);
    }

    .customer-address-edit-button {
        @include button-secondary('small');
        @include button-icon($mi-var-pencil);
    }
}
