@mixin merp-core-acl-layout-styles() {
    .core-role-acl {
        fieldset {
            min-width: 0;
        }

        .fieldset-children {
            overflow-x: auto;

            &::before {
                display: none;
            }

            > * {
                flex: 1 1 auto;
            }
        }

        .form-container {
            padding: 0;
        }
    }
}
