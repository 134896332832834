@mixin merp-core-user-layout-styles() {
    .user-sidebar {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'medium': rem-calc(300)
            ),
            $flex-grow: 0,
            $flex-shrink: 0
        );
    }

    .user-edit-container,
    .user-details-container,
    .user-password-container {
        @include grid-row($column-gutters: ());

        @include breakpoint('medium') {
            flex: 1 0 auto;
        }
    }

    .user-sidebar,
    .user-attributes,
    .edit-account-container,
    .user-change-password-container,
    .edit-user-attribute-container {
        @include box-padding(2);
    }

    .user-attributes,
    .edit-account-container,
    .user-change-password-container,
    .edit-user-attribute-container {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'medium': 50%
            )
        );
    }

    .user-attribute-table-item-container {
        @include attribute-table-cell(
            $responsive-sizes: (
                'small': 100%,
                'xlarge': 100% / 2,
                'xxlarge': 100% / 3
            )
        );
    }
}
