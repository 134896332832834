.packing-view-top {
    display: flex;
    border-bottom: 0;
    padding: rem-calc(0 15 15);
}

.packing-print-label-button,
.packing-get-shipping-information-button,
.packing-void-shipment-button,
.packing-print-pdf-with-labels-button {
    @include button-secondary();
}

.packing-print-label-icon,
.packing-get-shipping-information-icon,
.packing-print-pdf-icon,
.packing-mark-as-shipped-icon,
.packing-void-shipment-icon,
.packing-print-pdf-with-labels-icon {
    @include merp-icon($mi-var-sync); // @todo update placeholder
    margin-right: $button-icon-right-margin;
}

.shipping-labels {
    padding: rem-calc(0 30);
}

.packing-mark-as-shipped-button {
    @include button-secondary();
}

.packing-remove-from-assignment-button {
    background-color: get-color('red');
}

.packing-item-table {
    > tbody > tr {
        .packing-assignment-highlight-cell {
            background-color: get-color('yellow');

            &:hover {
                background-color: get-color('yellow');
            }

            &:nth-of-type(even) {
                background-color: get-color('yellow');

                &:hover {
                    background-color: get-color('yellow');
                }
            }
        }

        &.packing-assignment-highlight-early-row {
            background-color: transparentize(get-color('yellow'), 0.7);

            &:hover {
                background-color: transparentize(get-color('yellow'), 0.6);
            }

            &:nth-of-type(even) {
                background-color: transparentize(get-color('yellow'), 0.7);

                &:hover {
                    background-color: transparentize(get-color('yellow'), 0.6);
                }
            }
        }

        &.packing-assignment-highlight-late-row {
            background-color: transparentize(get-color('red'), 0.7);

            &:hover {
                background-color: transparentize(get-color('red'), 0.6);
            }

            &:nth-of-type(even) {
                background-color: transparentize(get-color('red'), 0.7);

                &:hover {
                    background-color: transparentize(get-color('red'), 0.6);
                }
            }
        }

        &.packing-assignment-highlight-in-time-row {
            background-color: transparentize(get-color('green'), 0.7);

            &:hover {
                background-color: transparentize(get-color('green'), 0.6);
            }

            &:nth-of-type(even) {
                background-color: transparentize(get-color('green'), 0.7);

                &:hover {
                    background-color: transparentize(get-color('green'), 0.6);
                }
            }
        }
    }
}
