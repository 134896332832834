@mixin merp-product-bundle-information-component-styles() {
    .product-bundle-information-fieldset {
        .fieldset-children {
            > .form-field {
                flex-basis: 100%;
            }
        }
    }

    .bundle-product-option {
        @include attribute-table();
        flex-basis: 100%;
        border-bottom: rem-calc(4) $global-border-style get-color($global-border-color);

        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding: $padding 0;
            }
        }

        > .form-field {
            @include attribute-table-cell();

            &.contains-button {
                flex-grow: 0;
            }
        }

        td {
            text-transform: none;
        }

        .user-defined {
            &.hide {
                display: none;
            }
        }

        &::before {
            border-top: 0;
        }
    }
}
