@mixin merp-feedback-component-styles() {
    $feedback-form-height: rem-calc(200) !default;

    .feedback-container {
        @include transition(transform);
        position: relative;
        width: 100%;

        &.is-open {
            transform: translateY((-1 * $feedback-form-height));
        }
    }

    .feedback-open-button {
        @include main-menu-item();
        margin-bottom: 0;
        border-top: rem-calc(2) solid get-color($main-menu-base-color);
        padding: rem-calc(15 20);
        width: 100%;
        text-transform: uppercase;
        font-weight: get-font-weight('bold');

        .feedback-container.is-open &,
        &.is-active,
        &:hover,
        &:focus {
            @include main-menu-item-hover();

            .feedback-open-icon {
                @include main-menu-item-icon-hover();
            }
        }

        .feedback-open-icon {
            @include merp-icon($mi-var-speech-bubble);
            @include main-menu-item-icon();
        }
    }

    .feedback-form-container {
        @include box-container($margin-ratio: 0, $border: false);
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: $feedback-form-height;

        .feedback-form {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }

        .form-field {
            &,
            label {
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
                margin: 0;
            }
        }
    }

    .feedback-message-field {
        flex: 1 0 auto;
        resize: none;
    }

    .feedback-submit-button {
        @include button-primary('large', $border-radius: 0);
    }

    .feedback-error-message {
        @include global-message('error-light', 'error-dark', $mi-var-cancel, 'error');
    }

    .feedback-success-message {
        @include global-message('success-light', 'success-dark', $mi-var-check-circled, 'success');
    }
}
