@mixin merp-shipment-edit-layout-styles() {
    .shipment-title-method {
        @include toolbar();
    }

    .shipment-title {
        flex-grow: 1;
    }

    .shipment-number-title {
        display: inline-block;
    }

    .shipment-group-view-wrapper {
        @include grid-row(
            $column-gutters: (
                'small': 0,
            )
        );
        height: 100%;
    }

    .shipment-group {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 75%
            )
        );
    }

    .shipment-group-shipping-information {
        @include grid-column(
            $responsive-sizes: (
                'small': 100%,
                'large': 25%
            )
        );
        background-color: get-color('blue-light');
    }

    .shipment-group,
    .shipment-group-shipping-information {
        @include box-padding(2);
    }

    .shipment-boxes {
        margin-bottom: rem-calc(15);
    }
}
