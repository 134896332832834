@mixin merp-inventory-assignment-add-item-button-styles() {
    .assignment-item-add-button {
        @include button-primary();
    }
}

@mixin merp-inventory-assignment-edit-button-styles() {
    .inventory-assignment-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-inventory-assignment-finish-button-styles() {
    .assignment-finish-button {
        @include button-secondary();
    }

    .assignment-finish-button-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-inventory-assignment-button-component-styles() {
    @include merp-inventory-assignment-add-item-button-styles();
    @include merp-inventory-assignment-edit-button-styles();
    @include merp-inventory-assignment-finish-button-styles();
}
