@mixin merp-product-revision-layout-styles() {
    .product-event-revision {
        .page-main-column {
            @include grid-row($column-gutters: ());

            > .revision-history-container {
                @include grid-column((), $flex-grow: 1);
                background-color: get-color('grey-lightest');

                @each $breakpoint, $border-width in $global-border-width {
                    @include breakpoint($breakpoint) {
                        border: rem-calc($border-width) $global-border-style get-color($global-border-color);
                    }
                }

                @each $breakpoint, $margin in $global-margin {
                    @include breakpoint($breakpoint) {
                        margin-right: $margin;
                        margin-bottom: $margin;
                    }
                }
            }

            > .revision-body-container {
                @include grid-column((), $flex-grow: 6);
            }
        }
    }
}
