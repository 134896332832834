@mixin merp-inventory-assignment-view-layout-styles() {
    .receiving-inventoryassignment-view {
        .inventory-assignment-product-wrapper {
            @include grid-row(
                $column-gutters: (
                    'small': 0,
                )
            );
        }

        .assignment-item-table-wrapper {
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 75%
                )
            );
        }

        .item-lot-information {
            @include box-padding(2);
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 25%
                )
            );
        }

        .form-container {
            padding: 0;

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    margin-bottom: $margin;
                }
            }
        }
    }
}
