@mixin merp-unpick-edit-layout-styles() {
    .picking-unpick-edit {
        .unpick-assignment-item {
            @include grid-row(
                $column-gutters: (
                    'small': 0,
                )
            );
        }

        .product-information {
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 75%
                )
            );
        }

        .item-lot-information {
            @include box-padding(2);
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 25%
                )
            );
        }

        .assignment-item-add-button {
            @include button-primary();
        }
    }
}
