@mixin merp-tables-component-styles() {
    table {
        &:not(.table-custom) {
            @include table(
                $is-hover: true,
                $row-hover: 'blue-lightest',
                $row-stripe-hover: 'blue-lightest'
            );

            thead {
                tr {
                    th {
                        padding: $table-head-padding;
                        text-transform: uppercase;
                        font-size: $table-head-font-size;

                        &:not(:first-child) {
                            border-left: $table-border-width $table-border-style get-color('black-pitch');
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        &:not(:first-child) {
                            border-left: $table-border-width $table-border-style get-color($table-border-color);
                        }
                    }

                    &.parent-item {
                        border: rem-calc(2) $global-border-style get-color('grey-medium');
                        background-color: get-color('grey-light');
                        cursor: pointer;

                        &:hover,
                        &:focus {
                            background-color: get-color('grey-medium');
                        }
                    }

                    &.hidden-child-item {
                        display: none;
                    }
                }
            }
        }
    }
}
