.open-shippable-orders-list-icon,
.shippable-orders-flyout-clear-icon,
.shippable-orders-flyout-save-icon,
.shippable-orders-flyout-start-icon,
.shippable-orders-flyout-close-icon {
    margin-right: $button-icon-right-margin;
}

.shippable-orders-delete-icon {
    @include merp-icon($mi-var-trash);
}

.shippable-orders-edit-button-icon {
    @include merp-icon($mi-var-pencil);
    margin-right: $button-icon-right-margin;
}

.open-shippable-orders-list-icon {
    @include merp-icon($mi-var-arrow-left); // @todo - change placeholder icon
}

.shippable-orders-flyout-container {
    @include data-grid-flyout();
    background-color: get-color('white');
}

.shippable-orders-flyout-header {
    flex: 0 0 auto;
    background: get-color('grey-light');
    text-align: center;
}

.shippable-orders-flyout-body {
    position: relative;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.shippable-orders-order {
    @include box-padding();
}

.shippable-orders-flyout-footer {
    @include box-section($padding-ratio: 0);
    flex: 0 0 auto;
}

.shippable-orders-flyout-footer-buttons {
    @include button-group($bottom-margin-ratio: 0);
}

.shippable-orders-flyout-clear-button,
.shippable-orders-flyout-save-button,
.shippable-orders-flyout-start-button,
.shippable-orders-flyout-close-button {
    @include button-group-item();
}

.shippable-orders-flyout-clear-button {
    @include button-secondary($border-radius: 0);

    .shippable-orders-flyout-clear-icon {
        @include merp-icon($mi-var-cancel);
    }
}

.shippable-orders-flyout-save-button {
    @include button-primary($border-radius: 0);

    .shippable-orders-flyout-save-icon {
        @include merp-icon($mi-var-save);
    }
}

.shippable-orders-flyout-start-button {
    @include button-primary($border-radius: 0);

    .shippable-orders-flyout-start-icon {
        @include merp-icon($mi-var-sync); // @todo: update placeholder icon
    }
}

.shippable-orders-flyout-close-button {
    @include button-tertiary($border-radius: 0);

    .shippable-orders-flyout-close-icon {
        @include merp-icon($mi-var-close);
    }
}

.shippable-orders-flyout-remove-item-button {
    @include button-secondary($button-size: 'small');
    margin-left: auto;
}

.shippable-orders-flyout-remove-item-icon {
    @include merp-icon($mi-var-trash);
}

.shippable-order {
    @include flex-accordion-row();
    margin-top: rem-calc(10);
    border-top: rem-calc(2) solid get-color('grey-light');
    padding-top: rem-calc(10);
}

.data-grid {
    tbody > tr {
        &.shippable-order-highlight-po-box-row {
            background-color: transparentize(get-color('yellow'), 0.7);

            &:hover {
                background-color: transparentize(get-color('yellow'), 0.6);
            }

            &:nth-of-type(even) {
                background-color: transparentize(get-color('yellow'), 0.7);

                &:hover {
                    background-color: transparentize(get-color('yellow'), 0.6);
                }
            }
        }

        &.shippable-order-highlight-hold-paybright-order-row {
            background-color: transparentize(get-color('red'), 0.7);
            font-weight: bold;

            &:hover {
                background-color: transparentize(get-color('red'), 0.6);
            }

            &:nth-of-type(even) {
                background-color: transparentize(get-color('red'), 0.7);

                &:hover {
                    background-color: transparentize(get-color('red'), 0.6);
                }
            }
        }
    }
}
