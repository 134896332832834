@mixin merp-core-login-form-component-styles() {
    .core-index-login,
    .core-index-forgotpassword,
    .core-index-resetpassword {
        background-color: get-color('black-light');

        .page-wrapper {
            margin: 0;
        }

        .page-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100%;
        }
    }

    .login-container,
    .forgot-password-container,
    .reset-password-container {
        @include box-container(0);
        box-shadow: rem-calc(10 10 0 8) transparentize(get-color('black-pitch'), 0.85);
        width: rem-calc(450);
        max-width: 100%;
    }

    .login-header,
    .forgot-password-header,
    .reset-password-header {
        @include box-section($padding-ratio: 4);
        @include set-background-color('primary');
        text-align: center;

        @each $breakpoint, $font-size in get-font-size('h1') {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
            }
        }

        img {
            width: rem-calc(160);
        }
    }

    .login-form,
    .forgot-password-form,
    .reset-password-form {
        @include box-section($padding-ratio: 4);
    }

    .login-form-instructions {
        margin-top: 0;
        text-align: center;
        font-weight: bold;

        @each $breakpoint, $font-size in get-font-size('subheading') {
            @include breakpoint($breakpoint) {
                font-size: $font-size;
            }
        }
    }

    .login-form-bottom,
    .forgot-password-bottom,
    .reset-password-bottom {
        @include toolbar($align-items: baseline);

        > * {
            flex: 1 0 100%;
        }

        @include breakpoint('medium') {
            justify-content: space-between;

            > * {
                flex: 0 0 auto;
            }
        }
    }

    .login-form-forgot-password {
        @include button-size();
        @include button-style(transparentize(get-color('white'), 1), $text-color: 'primary');
        padding-right: 0;
        padding-left: 0;
        font-weight: get-font-weight('bold');
    }

    .login-form-submit-button,
    .forgot-password-submit-button,
    .reset-password-submit-button {
        @include button-primary('login');

        @include breakpoint('medium') {
            order: 1;
        }
    }

    .login-copyright,
    .forgot-password-copyright,
    .reset-password-copyright {
        text-align: center;
        color: get-color('white');

        @each $breakpoint, $margin in $box-margin-bottom {
            @include breakpoint($breakpoint) {
                margin-top: (get-side($margin, 'top') * 2);
            }
        }
    }
}
