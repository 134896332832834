@mixin merp-shipment-edit-button-styles() {
    .shipment-assignment-edit-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-shipment-open-list-button-styles() {
    .open-shipment-list-icon {
        @include button-icon($mi-var-arrow-left);
    }
}

@mixin merp-shipment-group-view-button-styles() {
    .shipment-group-view-button {
        @include button-primary($button-size: 'small');
    }
}

@mixin merp-shipment-create-button-styles() {
    .shipment-create-button {
        @include button-primary();
    }
}

@mixin merp-shipment-finish-button-styles() {
    .shipment-finish-button {
        @include button-primary();
    }
}

@mixin merp-shipment-next-button-styles() {
    .shipment-next-button {
        @include button-secondary();
    }
}

@mixin merp-shipment-edit-cancel-button-styles() {
    .shipment-edit-cancel-button {
        @include button-secondary();
    }
}

@mixin merp-shipment-start-button-styles() {
    .shipment-start-icon {
        @include button-icon($mi-var-sync);
    }
}

@mixin merp-shipment-button-component-styles() {
    @include merp-shipment-edit-button-styles();
    @include merp-shipment-open-list-button-styles();
    @include merp-shipment-group-view-button-styles();
    @include merp-shipment-create-button-styles();
    @include merp-shipment-finish-button-styles();
    @include merp-shipment-next-button-styles();
    @include merp-shipment-edit-cancel-button-styles();
    @include merp-shipment-start-button-styles();
}
