@mixin form-label() {
    display: block;
    text-transform: uppercase;
    color: get-color('grey-dark-darker');
}

@mixin form-checkbox($checked-color: 'primary') {
    @include visually-hidden();

    + * {
        @include merp-icon($mi-var-checkbox-empty) {
            margin-right: 0.25em;
            font-size: 120%;
        }
    }

    &:checked {
        + * {
            @include merp-icon($mi-var-checkbox) {
                color: get-color($checked-color);
            }
        }
    }
}

@mixin form-checkbox-round($checked-color: 'primary') {
    @include visually-hidden();

    + * {
        @include merp-icon($mi-var-check-circled-empty) {
            margin-right: 0.25em;
            font-size: 120%;
        }
    }

    &:checked {
        + * {
            @include merp-icon($mi-var-check-circled) {
                color: get-color($checked-color);
            }
        }
    }
}

@mixin form-radio($checked-color: 'primary') {
    @include form-checkbox-round();
}
