@mixin merp-attribute-table-component-styles() {
    .attribute-table {
        @include attribute-table();
    }

    .attribute-table-item-container {
        @include attribute-table-cell();
    }

    .attribute-table-item {
        @include attribute-table-item();
    }

    .attribute-table-label {
        @include attribute-table-label();
    }

    .attribute-table-value {
        @include attribute-table-value();
    }

    .attribute-table-head {
        @include flex-accordion-row();

        .attribute-table-actions {
            margin-left: auto;
        }

        .attribute-table-actions-button {
            @include button-white-blue-hover($button-size: 'small');
        }
    }
}
