$font-family-lato: if(variable-exists('font-family-lato'), $font-family-lato, 'Lato');

@mixin merp-lato-font-styles() {
    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('thin');
        font-style: normal;
        src: url('#{$teemo-font-path}/lato/lato-thin.eot');
        src: url('#{$teemo-font-path}/lato/lato-thin.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-thin.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-thin.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-thin.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('thin');
        font-style: italic;
        src: url('#{$teemo-font-path}/lato/lato-thinitalic.eot');
        src: url('#{$teemo-font-path}/lato/lato-thinitalic.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-thinitalic.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-thinitalic.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-thinitalic.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('light');
        font-style: normal;
        src: url('#{$teemo-font-path}/lato/lato-light.eot');
        src: url('#{$teemo-font-path}/lato/lato-light.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-light.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-light.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-light.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('light');
        font-style: italic;
        src: url('#{$teemo-font-path}/lato/lato-lightitalic.eot');
        src: url('#{$teemo-font-path}/lato/lato-lightitalic.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-lightitalic.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-lightitalic.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-lightitalic.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('normal');
        font-style: normal;
        src: url('#{$teemo-font-path}/lato/lato-regular.eot');
        src: url('#{$teemo-font-path}/lato/lato-regular.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-regular.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-regular.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-regular.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('normal');
        font-style: italic;
        src: url('#{$teemo-font-path}/lato/lato-italic.eot');
        src: url('#{$teemo-font-path}/lato/lato-italic.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-italic.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-italic.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-italic.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('bold');
        font-style: normal;
        src: url('#{$teemo-font-path}/lato/lato-bold.eot');
        src: url('#{$teemo-font-path}/lato/lato-bold.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-bold.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-bold.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-bold.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('bold');
        font-style: italic;
        src: url('#{$teemo-font-path}/lato/lato-bolditalic.eot');
        src: url('#{$teemo-font-path}/lato/lato-bolditalic.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-bolditalic.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-bolditalic.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-bolditalic.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('black');
        font-style: normal;
        src: url('#{$teemo-font-path}/lato/lato-black.eot');
        src: url('#{$teemo-font-path}/lato/lato-black.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-black.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-black.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-black.ttf') format('truetype');
    }

    @font-face {
        text-rendering: optimizeLegibility;
        font-family: $font-family-lato;
        font-weight: get-font-weight('black');
        font-style: italic;
        src: url('#{$teemo-font-path}/lato/lato-blackitalic.eot');
        src: url('#{$teemo-font-path}/lato/lato-blackitalic.eot?#iefix') format('embedded-opentype'),
        url('#{$teemo-font-path}/lato/lato-blackitalic.woff2') format('woff2'),
        url('#{$teemo-font-path}/lato/lato-blackitalic.woff') format('woff'),
        url('#{$teemo-font-path}/lato/lato-blackitalic.ttf') format('truetype');
    }
}
