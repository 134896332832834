@mixin merp-unpick-edit-button-styles() {
    .unpick-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-unpick-list-button-styles() {
    .open-unpick-list-icon {
        @include merp-icon($mi-var-arrow-left); // @todo - change placeholder icon
    }
}

@mixin merp-unpick-finish-button-styles() {
    .assignment-finish-button {
        @include button-secondary();
    }

    .assignment-finish-button-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-picking-unpick-button-component-styles() {
    @include merp-unpick-edit-button-styles();
    @include merp-unpick-list-button-styles();
    @include merp-unpick-finish-button-styles();
}
