$product-store-border-width: if(variable-exists('product-store-border-width'), $product-store-border-width, rem-calc(4));
$product-store-border-style: if(variable-exists('product-store-border-style'), $product-store-border-style, $global-border-style);
$product-store-border-color: if(variable-exists('product-store-border-color'), $product-store-border-color, 'primary');

@mixin merp-store-switcher-component-styles() {
    .store-switcher,
    .website-switcher {
        @include toolbar($align-items: stretch);
        text-transform: uppercase;
        font-size: rem-calc(13);
        font-weight: get-font-weight('bold');

        .store-name,
        .website-name {
            flex-grow: 1;
            margin-top: rem-calc(-$product-store-border-width);
            border-top: $product-store-border-width $product-store-border-style get-color($product-store-border-color);
            color: get-color('primary');

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    padding: (($margin - $product-store-border-width) / 2) $margin;
                }
            }

            @include breakpoint('medium') {
                flex-grow: 0;
            }
        }

        .store-select,
        .website-select {
            flex: 1 0 auto;
            border-bottom: rem-calc(1) $global-border-style get-color($global-border-color);
            border-left: rem-calc(1) $global-border-style get-color($global-border-color);
            background-color: get-color('grey-lightest');

            .form-field {
                margin-bottom: 0;
            }

            .store-switcher-select,
            .website-switcher-select {
                @include form-select();
                border: 0;
                background-color: get-color('grey-lightest');
                width: auto;
                min-width: rem-calc(320);
            }
        }
    }
}
