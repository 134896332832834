@mixin merp-purchaseorder-button-component-styles() {
    .purchase-order-delete-button-icon {
        @include button-icon($mi-var-trash);
    }

    .purchase-order-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }

    .place-purchase-order-button-icon {
        @include button-icon($mi-var-backorder);
    }

    .place-purchase-order-button {
        @include button-secondary();
    }

    .purchaseorder-index-edit {
        .form-submit-button,
        .place-purchase-order-button {
            border-radius: 0;
        }

        .form-action-button-wrapper {
            display: inline-flex;
            border-radius: $button-border-radius;
            overflow: hidden;
        }
    }

    .purchase-order-complete-button-icon {
        @include button-icon($mi-var-check-circled);
    }

    .purchase-order-cancel-button-icon {
        @include button-icon($mi-var-cancel);
    }

    .purchase-order-complete-button,
    .purchase-order-cancel-button {
        @include button-tertiary();
    }
}
