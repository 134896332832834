@mixin merp-autocomplete-component-styles() {
    // TODO: Implement like dropdowns

    .ui-autocomplete {
        border: rem-calc(1) $global-border-style get-color('grey-light');
        background-color: get-color('white');

        .ui-menu-item {
            border-bottom: rem-calc(1) solid get-color('grey-light');
            cursor: pointer;
            list-style-type: none;

            @each $breakpoint, $padding in $global-padding {
                @include breakpoint($breakpoint) {
                    padding: ($padding / 2);
                }
            }

            &:hover,
            &:focus {
                background-color: get-color('primary');
                color: get-color('white');
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .ui-helper-hidden-accessible {
        @include visually-hidden();
    }
}
