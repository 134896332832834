@mixin merp-core-index-layout-styles() {
    .core-index-index {
        .page-main {
            flex-direction: row;
            flex-wrap: wrap;

            .recent-history {
                flex: 2 1 auto;

                @each $breakpoint, $margin in $global-margin {
                    @include breakpoint($breakpoint) {
                        margin-right: $margin;
                        margin-bottom: $margin;
                    }
                }
            }

            .statistics-information {
                flex: 1 0 auto;
                @each $breakpoint, $margin in $global-margin {
                    @include breakpoint($breakpoint) {
                        margin-bottom: $margin;
                    }
                }
            }

            .product-inventory-status {
                flex-basis: 100%;

                .table-wrapper {
                    display: flex;
                }

                .status-type {
                    flex: 1 0 auto;
                }
            }

            @each $breakpoint, $padding in $grid-column-gutters {
                @include breakpoint($breakpoint) {
                    padding: $padding;
                }
            }
        }
    }
}
