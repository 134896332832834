@mixin merp-unpick-view-layout-styles() {
    .picking-unpick-view {
        .page-main {
            @include box-padding(2);
        }

        .unpick-assignment-product-wrapper {
            @include grid-row(
                $column-gutters: (
                    'small': 0,
                )
            );
        }

        .unpick-assignment-table-wrapper {
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 75%
                )
            );
        }

        .item-lot-information {
            @include box-padding(2);
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 25%
                )
            );
        }

        .form-container {
            margin-bottom: 2em;
            padding: 0;
        }
    }
}
