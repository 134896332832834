@mixin merp-sales-order-address-component-styles() {
    .address-header {
        @include flex-accordion-row();

        .address-header-button {
            @include button-white-blue-hover('small');
            margin-left: auto;
        }

        h5 {
            @include typography-heading-margin($heading-type: 'h5', $last-child-no-margin: false);
        }
    }

    .order-view-addresses {
        display: flex;
        flex-wrap: wrap;

        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-top: $margin;
                margin-bottom: $margin;
            }
        }

        .address-wrapper {
            flex: 1;

            &:last-of-type {
                margin-left: rem-calc(30);
            }

            > .address-content {
                padding: rem-calc(25);

                @each $breakpoint, $border-width in $global-border-width {
                    @include breakpoint($breakpoint) {
                        border: rem-calc($border-width) $global-border-style get-color('blue-light');
                    }
                }
            }
        }
    }
}
