.item-table {
    .item-row-print-button {
        @include button-white-blue-hover('small');

        .item-row-print-icon {
            @include merp-icon($mi-var-sync); // @todo: update icon
            margin-right: $button-icon-right-margin;
        }
    }
}

.item-table-massaction-button {
    @include button-primary($border-radius: 0);
}

.item-table-massaction-actions {
    @include button-group($bottom-margin-ratio: 0);
    display: inline-flex;
    border-radius: $button-border-radius;
    overflow: hidden;
}

.item-table-massaction-checkbox {
    line-height: 1;
    font-size: rem-calc(16);

    input[type='checkbox'] {
        @include form-checkbox();
    }
}
