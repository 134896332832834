@mixin merp-sales-linked-document-tabs-styles() {
    .sales-view-tab-items {
        @include grid-column(('small': 100%, 'xxlarge': rem-calc(280)), $flex-grow: 0);
        background-color: get-color('blue-light');
        font-size: rem-calc(16);
    }

    .linked-documents-header {
        @each $breakpoint, $margin in $global-margin {
            @include breakpoint($breakpoint) {
                margin-bottom: $margin;
            }
        }
    }

    .linked-documents-tabs {
        margin: 0;

        @each $breakpoint, $border-width in $hr-border-width {
            @include breakpoint($breakpoint) {
                border-top: rem-calc($border-width) $global-border-style get-color($global-border-color);
            }
        }

        > li {
            margin: 0;
            list-style: none;

            > a {
                display: block;
                padding: rem-calc(12);
                width: 100%;
                color: get-color('black');

                &:hover,
                &:focus {
                    color: get-color('primary');
                }
            }

            &.active {
                background-color: get-color('primary');
                font-weight: get-font-weight('bold');

                a {
                    color: get-color('white');
                }
            }
        }
    }
}
