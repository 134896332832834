@mixin merp-inventory-assignment-edit-layout-styles() {
    .receiving-inventoryassignment-edit {
        .inventory-assignment-product-wrapper {
            @include grid-row(
                $column-gutters: (
                    'small': 0,
                )
            );
        }

        .assignment-item-table-wrapper {
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 75%
                )
            );
        }

        .item-lot-information {
            @include box-padding(2);
            @include grid-column(
                $responsive-sizes: (
                    'small': 100%,
                    'large': 25%
                )
            );
        }

        .inventory-assignment-form-actions {
            display: inline-flex;
            border-radius: $button-border-radius;
            overflow: hidden;

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    margin-bottom: $margin;
                }
            }
        }
    }
}
