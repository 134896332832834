@mixin merp-vendor-button-component-styles() {
    .vendor-edit-icon {
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
    }

    .vendor-address-edit-button {
        @include button-secondary('small');
        @include merp-icon($mi-var-pencil);
        margin-right: $button-icon-right-margin;
        margin-left: auto;
    }
}
