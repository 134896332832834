@mixin merp-customer-view-layout-styles() {
    .customer-index-view {
        .customer-main {
            @include grid-row($column-gutters: ('small': 0));
        }

        .customer-attributes {
            @include box-padding(2);
            @include grid-column($responsive-sizes: ('small': 100%, 'large': 75%));
        }

        .customer-statistics {
            @include box-padding(2);
            @include grid-column($responsive-sizes: ('small': 100%, 'large': 25%));
        }
    }
}
