@mixin merp-packing-box-component-styles() {
    .packing-box-block-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .packing-information-list {
        dl {
            display: inline-block;
            margin-right: rem-calc(20);
        }
    }

    .packing-box-information-list {
        dl {
            display: inline-block;
            margin: rem-calc(10);
        }
    }
}
