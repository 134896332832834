@mixin merp-attribute-set-edit-button-styles() {
    .attribute-set-edit-icon {
        @include button-icon($mi-var-pencil);
    }
}

@mixin merp-attribute-set-delete-button-styles() {
    .attribute-set-delete-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-attribute-set-save-button-styles() {
    .attribute-set-save-button {
        @include button-primary();
    }

    .attribute-set-save-icon {
        @include button-icon($mi-var-save);
    }
}

@mixin merp-attribute-group-delete-button-styles() {
    .attribute-group-delete-button-icon {
        @include button-icon($mi-var-trash);
    }
}

@mixin merp-attribute-delete-button-styles() {
    .attribute-delete-button-icon {
        @include button-icon($mi-var-trash);
    }

    .delete-attribute-button {
        @include button-secondary();
    }
}

@mixin merp-eav-button-component-styles() {
    @include merp-attribute-set-edit-button-styles();
    @include merp-attribute-set-delete-button-styles();
    @include merp-attribute-set-save-button-styles();
    @include merp-attribute-group-delete-button-styles();
    @include merp-attribute-delete-button-styles();
}
