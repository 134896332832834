$titlebar-background-color: if(variable-exists('titlebar-background-color'), $titlebar-background-color, 'blue-light');
$titlebar-border-width: if(variable-exists('titlebar-border-width'), $titlebar-border-width, rem-calc(4));
$titlebar-border-style: if(variable-exists('titlebar-border-style'), $titlebar-border-style, $global-border-style);
$titlebar-border-color: if(variable-exists('titlebar-border-color'), $titlebar-border-color, 'grey-medium-darker');

@mixin titlebar-icon($icon) {
    @include merp-icon($icon);
    vertical-align: -15%;
    color: get-color('primary');
    font-size: 150%;
}

@mixin merp-titlebar-component-styles() {
    .title-bar-section,
    .grid-title-bar-section,
    .form-title-bar-section {
        @include toolbar($align-items: center);

        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                padding-right: $padding;
                padding-left: $padding;
            }
        }

        @each $breakpoint, $padding in $box-padding {
            @include breakpoint($breakpoint) {
                padding-top: $padding;
                padding-bottom: $padding;
            }
        }

        border-bottom: $titlebar-border-width $titlebar-border-style get-color($titlebar-border-color);
        background: get-color($titlebar-background-color);
    }

    .title-bar-title,
    .grid-title-bar-title,
    .form-title-bar-title {
        flex-grow: 99999;
    }

    .title-bar-title-heading,
    .grid-title-bar-title-heading,
    .form-title-bar-title-heading {
        margin: 0;
        font-weight: get-font-weight('normal');

        @each $breakpoint, $size in get-font-size('titlebar-heading') {
            @include breakpoint($breakpoint) {
                font-size: $size;
            }
        }
    }

    .title-bar-button-group,
    .grid-title-bar-button-group,
    .form-title-bar-button-group {
        @include button-group(0);
        flex-grow: 1;
        border-radius: $button-border-radius;
        overflow: hidden;

        > * {
            @include button-group-item();
        }
    }

    .title-bar-button,
    .grid-title-bar-button,
    .form-title-bar-button {
        @include button-primary();

        .title-bar-button-group &,
        .grid-title-bar-button-group &,
        .form-title-bar-button-group & {
            border-radius: 0;
        }
    }

    .title-bar-edit-button {
        @include button-primary();
    }

    .title-bar-edit-button-icon {
        @include button-icon($mi-var-pencil);
    }
}
