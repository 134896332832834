@mixin merp-core-permissions-accordion-component-styles() {
    .permission-section {
        @include accordion-container();

        @each $breakpoint, $border-width in $hr-border-width {
            @include breakpoint($breakpoint) {
                border: $border-width $hr-border-style get-color($hr-border-color);
            }
        }
    }

    .permission-group {
        @include accordion-item();

        & + & {
            @each $breakpoint, $border-width in $hr-border-width {
                @include breakpoint($breakpoint) {
                    border-top: $border-width $hr-border-style get-color($hr-border-color);
                }
            }
        }
    }

    .permission-group-title {
        @include accordion-title($text-align: left, $justify-content: left);
        text-transform: uppercase;

        &::before {
            color: get-color('grey-dark');
            font-size: rem-calc(24);
        }

        .is-active > & {
            @include set-background-color('primary');

            &::before {
                color: inherit;
            }
        }
    }

    .permission-group-content {
        @include accordion-body($padding: ('small': 0, 'medium': 0));
    }

    table.permission-table {
        @include table($is-hover: true, $is-striped: false);
        margin-bottom: 0;
        text-align: left;

        thead,
        tbody,
        tfoot {
            border: 0;
        }

        tbody {
            tr {
                border-bottom: $permission-row-border-width $permission-row-border-style get-color($permission-row-border-color);
            }
        }

        th,
        td {
            vertical-align: middle;
        }

        .checkbox-proxy {
            line-height: 1;
            font-size: rem-calc(24);
        }

        @for $level from 0 through $permission-level-max {
            .permission-level-#{$level} {
                @if ($level % 2 == 0) {
                    background-color: get-color($table-striped-background-color);

                    &:hover,
                    &:focus {
                        background-color: get-color($table-row-stripe-hover);
                    }
                } @else {
                    background-color: get-color($table-background-color);

                    &:hover,
                    &:focus {
                        background-color: get-color($table-row-hover);
                    }
                }

                th {
                    position: relative;

                    @each $breakpoint, $padding in $table-padding {
                        @include breakpoint($breakpoint) {
                            padding-left: (get-side($padding, 'left') + $permission-level-padding * $level);
                        }
                    }

                    &::before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: get-color('grey-medium-darker');
                        width: ($permission-level-padding * $level);
                        height: 100%;
                        content: $non-breaking-space;
                    }
                }
            }
        }
    }

    .permission-description {
        display: block;
        font-size: 80%;
        font-weight: normal;

        &.hide {
            display: none;
        }
    }
}
