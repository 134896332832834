@mixin tabs-container() {
    @include unstyled-list();

    @each $breakpoint, $border-width in $hr-border-width {
        @include breakpoint($breakpoint) {
            border-top: $border-width $hr-border-style get-color($hr-border-color);
            border-right: $border-width $hr-border-style get-color($hr-border-color);
            border-left: $border-width $hr-border-style get-color($hr-border-color);
        }
    }
}

@mixin tabs-item() {
    @include set-background-color('grey-lightest');
    @include transition((background-color, border-color));
    margin-bottom: 0;

    @each $breakpoint, $border-width in $hr-border-width {
        @include breakpoint($breakpoint) {
            border-bottom: $border-width $hr-border-style get-color($hr-border-color);
        }
    }

    &:hover,
    &:focus,
    &.is-active {
        @include set-background-color('white');
        border-color: get-color('primary');
    }
}

@mixin tabs-title() {
    display: block;
    color: inherit;

    @each $breakpoint, $padding in $permission-tab-padding {
        @include breakpoint($breakpoint) {
            padding: $padding;
        }
    }
}
