// TODO: Refactor color names

$color-palette: (
    'red': #db0039,
    'red-light': #fae6eb,
    'red-dark': #810021,
    'yellow': #ffcc05,
    'yellow-light': #fff8ea,
    'yellow-dark': #806703,
    'green': #1ec264,
    'green-light': #edfcf2,
    'green-dark': #0e5c30,
    'blue': #0073db,
    'blue-light': #eceff5,
    'blue-lightest': #e5f3ff,
    'blue-dark': #003c72,
    'teal': #00c9db,
    'purple': #a000db,
    'white': #ffffff,
    'grey-lightest': #f7f9fd,
    'grey-light': #e6e6e6,
    'grey-medium': #d4d8e0,
    'grey-medium-darker': #d7dbe4,
    'grey-dark-lighter': #9da9ba,
    'grey-dark': #8a8a8a,
    'grey-dark-darker': #6a717b,
    'black-lighter': #4a516c,
    'black-light': #2f3345,
    'black': #26293d,
    'black-pitch': #000000
);

$color-contexts: (
    'primary': 'blue',
    'primary-light': 'blue-light',
    'primary-dark': 'blue-dark',
    'secondary': 'black-lighter',
    'tertiary': 'black-light',
    'success': 'green',
    'success-light': 'green-light',
    'success-dark': 'green-dark',
    'warning': 'yellow',
    'warning-light': 'yellow-light',
    'warning-dark': 'yellow-dark',
    'error': 'red',
    'error-light': 'red-light',
    'error-dark': 'red-dark'
);

$timing-durations: (
    'fastest': 40ms,
    'fast': 80ms,
    'moderate': 120ms,
    'slow': 200ms,
    'spin': 1000ms,
    'success-failure': 3000ms
);

$global-font-size: 14px;

$global-border-color: 'grey-medium';

$global-border-width: (
    'small': 2
);

$z-indexes: (
    'top-bar': 100,
    'main-menu': 110,
    'data-grid-flyout': 120,
    'ajax-overlay': 1000,
    'modal-overlay': 9999
);

$responsive-menu-breakpoint: 'large';

$topbar-heights: (
    'small': rem-calc(70)
);

$footer-heights: (
    'small': rem-calc(70)
);

$main-menu-widths: (
    'small': rem-calc(280)
);

$main-menu-base-color: 'black';
$main-menu-item-hover-color: 'primary';
$main-menu-text-color: 'grey-dark-lighter';
$main-menu-text-hover-color: 'white';
$main-menu-color-scale-ratio: 5%;
$main-menu-nested-iterations: 5;

$hr-border-width: (
    'small': rem-calc(2)
);

$anchor-text-decoration: none;

$button-border-width: (
    'small': 0
);
$button-border-radius: rem-calc(4);
$button-font-weight: 'bold';
$button-icon-right-margin: 0.2em;

$button-sizes: (
    'small': (
        'small': (
            'padding': rem-calc(4 6),
            'font-size': rem-calc(12),
            'line-height': 1.15
        )
    ),
    'regular': (
        'small': (
            'padding': rem-calc(10 12),
            'font-size': rem-calc(13),
            'line-height': 1.15
        )
    ),
    'large': (
        'small': (
            'padding': rem-calc(14 16),
            'font-size': rem-calc(13),
            'line-height': 1.15
        )
    ),
    'login': (
        'small': (
            'padding': rem-calc(8 16),
            'font-size': rem-calc(13),
            'line-height': 1.15
        )
    )
);

$font-family-lato: 'Lato';

$font-stacks: (
    'sans-serif': ($font-family-lato, sans-serif),
    'serif': (serif),
    'monospace': (monospace)
);

$font-sizes: (
    'normal': (
        'small': 1rem
    ),
    'h1': (
        'small': 1.802rem,
        'large': 2.488rem
    ),
    'h2': (
        'small': 1.602rem,
        'large': 2.074rem
    ),
    'h3': (
        'small': 1.424rem,
        'large': 1.728rem
    ),
    'h4': (
        'small': 1.266rem,
        'large': 1.44rem
    ),
    'h5': (
        'small': 1.125rem,
        'large': 1.2rem
    ),
    'h6': (
        'small': 1rem
    ),
    'p': (
        'small': inherit
    ),
    'li': (
        'small': inherit
    ),
    'cite': (
        'small': 80%
    ),
    'small-element': (
        'small': 80%
    ),
    'form-help': (
        'small': 80%
    ),
    'form-error': (
        'small': 80%
    ),
    'input': (
        'small': 1rem
    ),
    'sneaky-label': (
        'small': 0.625rem
    ),
    'subheading': (
        'small': rem-calc(18),
        'large': rem-calc(22)
    ),
    'titlebar-heading': (
        'small': 1.424rem,
        'large': 1.728rem
    )
);

$non-breaking-space: '\00a0';

$heading-font-weight: 'bold';

$select-triangle-color: 'grey-dark-darker';
$select-triangle-size: rem-calc(10);
$form-margin: (
    'small': rem-calc(20)
);

$user-img-size: (
    'small': rem-calc(100),
    'large': rem-calc(160)
);

$input-background-color: 'blue-light';
$input-border-style: none;
$input-border-radius: 0;
$input-padding: rem-calc(16 12);
$input-placeholder-color: 'grey-dark-darker';
$input-border-width: (
    'small': 0
);

$sneaky-label-padding-ratio: 0.6;
$sneaky-label-vertical-offset: rem-calc(3);
$sneaky-label-text-transform: uppercase;
$sneaky-label-color: $input-placeholder-color;

$button-text-transform: uppercase;

//$product-categories-item-margin: rem-calc(1);
//$product-categories-item-padding: rem-calc(8);
//$product-categories-item-columns: (
//    'small': 1,
//    'medium': 2,
//    'large': 3
//);
//
//$feedback-form-height: rem-calc(200);

$table-border-width: rem-calc(2);
$table-border-color: 'white';
$table-head-padding: rem-calc(10);
$table-head-font-size: rem-calc(12);
$table-head-background-color: 'black';
$table-background-color: 'blue-light';
$table-striped-background-color: 'white';
$table-row-hover: 'blue-light';
$table-row-stripe-hover: 'blue-light';

$grid-actions-margin: rem-calc(10);

$permission-tab-padding: (
    small: rem-calc(10 15)
);
$permission-level-max: 5;
$permission-level-padding: rem-calc(20);
$permission-row-border-width: rem-calc(1);
$permission-row-border-style: solid;
$permission-row-border-color: 'grey-medium';
