@mixin merp-core-user-sidebar-component-styles() {
    .user-sidebar {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: get-color('grey-lightest');
    }

    .user-image {
        flex: 0 1 auto;
        border: rem-calc(2) $global-border-style get-color('grey-medium-darker');
        border-radius: rem-calc(99999);
        background-color: get-color('white');
        overflow: hidden;

        img {
            object-fit: cover;

            @each $breakpoint, $size in $user-img-size {
                @include breakpoint($breakpoint) {
                    width: $size;
                    height: $size;
                }
            }
        }
    }

    .user-name {
        flex: 0 1 auto;
    }
}
