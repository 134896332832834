@mixin merp-product-edit-layout-styles {
    .product-index-edit {
        .fieldset-descriptions {
            .fieldset-children {
                .form-field {
                    flex-basis: 100%;
                }
            }
        }

        .form-action-button-wrapper {
            display: inline-flex;
            border-radius: $button-border-radius;
            overflow: hidden;

            @each $breakpoint, $margin in $global-margin {
                @include breakpoint($breakpoint) {
                    margin-bottom: $margin;
                }
            }
        }
    }
}
