@mixin merp-sales-manual-flyout-component-styles() {
    $pos-cart-width: rem-calc(500) !default;

    .sales-manual-edit {
        .sales-manual-flyout-container {
            @include data-grid-flyout();
            @include data-grid-cart();
            background-color: get-color('white');
            width: $pos-cart-width;
        }

        .sales-manual-flyout-header {
            @include data-grid-flyout-header();
        }

        .sales-manual-flyout-body {
            @include box-padding($padding-ratio: 2);
            position: relative;
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .sales-manual-flyout-footer {
            @include box-section($padding-ratio: 0);
            flex: 0 0 auto;
        }

        .sales-manual-flyout-close-button {
            @include button-tertiary($border-radius: 0);

            .sales-manual-flyout-close-icon {
                @include merp-icon($mi-var-close);
                margin-right: $button-icon-right-margin;
            }
        }

        .open-sales-manual-list-icon {
            @include merp-icon($mi-var-arrow-left); // @todo - change placeholder icon
            margin-right: $button-icon-right-margin;
        }

        .item-row-delete-button {
            @include button-secondary('small');
        }

        .item-row-trash-icon {
            @include merp-icon($mi-var-trash);
            margin-right: $button-icon-right-margin;
        }

        .sales-manual-cart-complete {
            @include button-primary();
        }

        .sales-manual-cart-heading {
            margin-right: rem-calc(5);
            font-weight: get-font-weight('bold');
        }

        .sales-manual-cart-payment {
            display: inline-block;
            width: auto;
        }

        &.has-data-grid-cart {
            .page-wrapper {
                @include breakpoint($data-grid-cart-responsive-breakpoint) {
                    margin-right: $pos-cart-width;
                }
            }
        }
    }
}
