@mixin merp-product-image-component-styles() {
    .product-image {
        width: rem-calc(250);
        border: rem-calc(2) $global-border-style get-color('grey-medium-darker');
    }
}

@mixin merp-product-media-gallery-edit-component-styles() {
    .product-media-gallery-images {
        @include grid-row();
    }

    .product-image-item-edit {
        $responsive-sizes: (
            'small': 100%,
            'medium': 50%,
            'large': 30%,
        );

        @include grid-column($responsive-sizes, $flex-grow: 0, $flex-shrink: 0);

        margin-bottom: rem-calc(10);
    }

    .product-image-wrapper {
        @include grid-row();
    }

    .product-media-gallery-image-actions, .product-image-container {
        $responsive-sizes: (
            'small': 100%,
            'large': 50%,
        );

        @include grid-column($responsive-sizes);
    }

    .product-image-item-edit-role {
        list-style: none;
        margin-left: 0;
    }

    .product-media-gallery-image-action-remove {
        @include button-primary();
    }
}
