$recent-history-list-border-size: if(variable-exists('recent-history-list-border-size'), $recent-history-list-border-size, rem-calc(3));

@mixin merp-log-component-styles() {
    .log-icon-catalog {
        @include merp-icon($mi-var-catalog);
    }

    .log-icon-customers {
        @include merp-icon($mi-var-customers);
    }

    .log-icon-sales {
        @include merp-icon($mi-var-sales);
    }

    .log-icon-shipment {
        @include merp-icon($mi-var-shipping-truck);
    }

    .log-icon-switch {
        @include merp-icon($mi-var-switch);
    }

    .log-icon-location {
        @include merp-icon($mi-var-vendor);
    }

    .log-icon-product-location {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-vendor {
        @include merp-icon($mi-var-vendor);
    }

    .log-icon-magento {
        @include merp-icon($mi-var-magento);
    }

    .log-icon-shopify {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-invoice {
        @include merp-icon($mi-var-note);
    }

    .log-icon-website {
        @include merp-icon($mi-var-channel);
    }

    .log-icon-tax {
        @include merp-icon($mi-var-dollar);
    }

    .log-icon-shipping {
        @include merp-icon($mi-var-shipping-truck);
    }

    .log-icon-unpick {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-box {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-purchase-order {
        @include merp-icon($mi-var-purchasing);
    }

    .log-icon-shipment-assignment {
        @include merp-icon($mi-var-shipping-truck);
    }

    .log-icon-unpack-assignment {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-product-vendor {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-packing-supply {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-packing-supply-rule {
        @include merp-icon($mi-var-sync);
    }

    .log-icon-receiving {
        @include merp-icon($mi-var-receiving);
    }

    .log-icon-inventory-assignment {
        @include merp-icon($mi-var-sync);
    }

    .recent-history-list {
        margin: 0;
        border-top: $recent-history-list-border-size $global-border-style get-color($global-border-color);
        border-bottom: $recent-history-list-border-size $global-border-style get-color($global-border-color);
        list-style: 'none';

        @each $breakpoint, $padding in $grid-column-gutters {
            @include breakpoint($breakpoint) {
                margin-bottom: $padding;
            }
        }

        li {
            display: flex;
            align-items: flex-start;
            margin: 0;

            @each $breakpoint, $padding in $grid-column-gutters {
                @include breakpoint($breakpoint) {
                    padding: ($padding / 2);
                }
            }

            &:nth-child(odd) {
                background-color: get-color('blue-light');
            }
        }

        .recent-history-list-item-description {
            flex: 1 1 auto;
            align-self: center;
        }

        .recent-history-list-item-icon,
        .recent-history-list-item-date {
            flex: 0 1 auto;
        }

        .recent-history-list-item-date {
            font-weight: get-font-weight('bold');
        }

        .recent-history-list-item-icon {
            margin-right: rem-calc(10);
            line-height: 1;
            color: get-color('primary');
            font-size: 200%;
        }
    }

    .recent-history-list-expand-button {
        @include button-primary();
    }
}
