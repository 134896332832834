@mixin merp-product-pricing-item-styles() {
    .product-pricing-item {
        @include toolbar($align-items: center);
    }

    .product-pricing-label {
        flex-grow: 1;
        text-transform: uppercase;
        font-size: rem-calc(10);
    }

    .product-pricing-value {
        font-weight: get-font-weight('bold');
    }

    .product-pricing-percentage {
        font-weight: get-font-weight('normal');
    }
}

@mixin merp-product-info-icon-styles() {
    margin-right: $button-icon-right-margin;
    vertical-align: -12%;
    color: get-color('primary');
    font-size: 300%;
}

@mixin merp-product-pricing-cost-icon-styles() {
    .product-pricing-cost-icon {
        @include merp-icon($mi-var-cost);
        @include merp-product-info-icon-styles();
    }
}

@mixin merp-product-pricing-margin-icon() {
    .product-pricing-margin-icon {
        @include merp-icon($mi-var-markup);
        @include merp-product-info-icon-styles();
    }
}

@mixin merp-product-pricing-selling-price-icon() {
    .product-pricing-selling-price-icon {
        @include merp-icon($mi-var-selling-price);
        @include merp-product-info-icon-styles();
    }
}

@mixin merp-product-pricing-ideal-price-icon() {
    .product-pricing-ideal-price-icon {
        @include merp-icon($mi-var-ideal-price);
        @include merp-product-info-icon-styles();
    }
}

@mixin merp-product-pricing-special-price-icon() {
    .product-pricing-special-price-icon {
        @include merp-icon($mi-var-special-price);
        @include merp-product-info-icon-styles();
    }
}

@mixin merp-product-pricing-icon-styles() {
    @include merp-product-pricing-cost-icon-styles();
    @include merp-product-pricing-margin-icon();
    @include merp-product-pricing-selling-price-icon();
    @include merp-product-pricing-ideal-price-icon();
    @include merp-product-pricing-special-price-icon();
}

@mixin merp-product-pricing-component-styles() {
    @include merp-product-pricing-item-styles();
    @include merp-product-pricing-icon-styles();
}
